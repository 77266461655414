// import YouTube from "react-youtube";
import { styled } from "@mui/material";
import osImg from "../../../images/landing/tos-hero-image.png";

const OsImage = styled("div")(({ theme }) => ({
  maxWidth: "1084px",
  width: "1084px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  [theme.breakpoints.down(2300)]: {
    width: "calc(100% - 40px)",
  },

  [theme.breakpoints.down(760)]: {
    width: "100%",
  },
}));

const TublianOsVideo: React.FC = () => {
  // const [videoStarted, setVideoStarted] = useState(false);

  // const onReady = (event: { target: { pauseVideo: () => void } }) => {
  //   event.target.pauseVideo();
  //   setVideoStarted(true);
  // };

  // const options = {
  //   width: "100%",
  //   height: "100%",
  //   playerVars: {
  //     autoplay: 0,
  //     controls: 0,
  //     rel: 0,
  //   },
  // };

  // const youtubeRef = useRef<any>();

  return (
    <OsImage>
      <img
        style={{ width: "100%" }}
        src={osImg}
        alt="Tublian Operating system"
      />

      {/* <YouTube
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 40,
        }}
        videoId="cYMZ5uCP7Dc"
        opts={options}
        onReady={onReady}
        ref={youtubeRef}
      /> */}
    </OsImage>
  );
};

export default TublianOsVideo;
