import { styled, Typography, Box } from "@mui/material";
import amazon from "../../../images/landing/amazon.svg";
import discord from "../../../images/landing/discordCompany.svg";
import linkedin from "../../../images/landing/linkedinCompany.svg";
import google from "../../../images/landing/googleCompany.svg";
import youtube from "../../../images/landing/youtubeCompany.svg";
import github from "../../../images/landing/githubCompany.svg";
import boostSpeed from "../../../images/landing/boostSpeed.png";
import evolvingCredibility from "../../../images/landing/evolvingCredibility.svg";
import creatorIllustration from "../../../images/landing/creatorIllustration.png";
import buildAtScale from "../../../images/landing/buildAtScale.png";
import becomeCreator from "../../../images/landing/becomeCreator.png";
import discoverOpportunitiesIllustration from "../../../images/landing/discoverOpportunitiesIllustration.png";
import makeMoney from "../../../images/landing/makeMoney.png";

import buildAtScaleIllustration from "../../../images/landing/buildAtScaleIllustration.png";
import { Link } from "react-router-dom";
import { smallArrowRightSvg } from "../../UserHome/Base/SVG";

export const StayRelevantSubheader = () => (
  <>
    <Typography>
      The future rewards those who take action. Use Tublian's personalized AI copilot to learn, grow, and build your online credibility. With Tublian Streetcred—a developer credit score based on your skills and work, not just experience—you'll stand out and seize opportunities in the AI revolution.
    </Typography>
    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/become-a-creator">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const DiscoverOpportunitiesSubHeader = () => (
  <>
    <Typography>
      Leverage your Streetcred and Tublian BizDev AI agent to match with projects that maximize your income. Tublian's AI agents work around the clock to find opportunities,
      so you can focus on your passion and creativity.
    </Typography>
    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/discover-opportunities">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const TublianOSSubheader = () => (
  <>
    <Typography>
      As AI makes software development faster and more accessible, demand will surge.
      With Tublian OS, you can manage multiple freelance projects, tackle large-scale
      development, and significantly boost your earnings. Tublian OS combines the
      power of AI agents to simplify building and project management.
    </Typography>

    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/tublian-os">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const companiesData = [
  {
    image: github,
    alt: "github",
  },
  {
    image: youtube,
    alt: "youtube",
  },
  {
    image: amazon,
    alt: "amazon",
  },
  {
    image: linkedin,
    alt: "linkedin",
  },
  {
    image: discord,
    alt: "discord",
  },

  {
    image: google,
    alt: "google",
  },
];

export const techJourneyEmpowermentData = [
  {
    img: boostSpeed,
    header: "Speed Matters",
    subheader: "To remain in demand, we must rapidly learn, create, and adapt",
    alt: "Boost Speed & Relevance",
    id: 1,
  },
  {
    img: evolvingCredibility,
    header: "Certifications Fall Short",
    subheader:
      "While AI dominates coding tests and certifications, developers must carve out a fresh path to credibility.",
    alt: "Evolving Credibility",
    id: 2,
  },
];

export const featuresData = [
  {
    img: creatorIllustration,
    header: "Create with Confidence",
    subheader: <StayRelevantSubheader />,
    alt: "Create with Confidence",
    id: 1,
  },

  {
    img: discoverOpportunitiesIllustration,
    subImage: true,
    subImageMsg: "Coming Soon",
    header: "Discover Opportunities",
    subheader: <DiscoverOpportunitiesSubHeader />,
    alt: "Discover Opportunities",
    id: 2,
  },
  {
    img: buildAtScaleIllustration,
    subImage: true,
    subImageMsg: "Early Access",
    header: "Build At Scale",
    subheader: <TublianOSSubheader />,
    alt: "Build At Scale",
    id: 3,
  },
];

export const ourPlanOne = [
  {
    img: becomeCreator,
    header: "Skill Up & Innovate",
    subheader:
      "Turn your ideas into real software solutions. Use AI to enhance your skills and discover new ways to innovate.",
    alt: "Learn",
    id: "1",
  },
  {
    img: makeMoney,
    header: "Earn More",
    subheader:
      "Stand out in the freelancing world with Tublian Streetcred. Our AI BizDev Agent helps you find niche opportunities, optimize pricing, and craft winning proposals to boost your success",
    alt: "Discover Opportunities",
    id: "2",
  },
  {
    img: buildAtScale,
    header: "Build Smarter, Scale Faster",
    subheader:
      "Boost your productivity with AI agents. Get more done in less time and scale your software effortlessly.",
    alt: "Build at Scale",
    id: "3",
  },
];

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    gap: 8,
    lineHeight: "20px",
    textDecoration: "none",
    fontWeight: 700,
    fontSize: 16,
    color: "#fefefe",

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
      textDecoration: "underline",
    },
  },
}));
