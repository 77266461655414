import { useEffect, useState } from "react";
import { userProfile } from "./libs/UserProfile";
import { AppContext } from "./libs/contextLib";
import Routes from "./Routes";
import ReactGA from "react-ga";
import ThemeProvider from "./themes/theme-2-0";
import FT from "./libs/FT";
import ProfileEndpoint from "./libs/ProfileEndpoint";
import { ChatBotProvider } from "./components/Context/ChatBotContext";
import { TublianOsProvider } from "./components/Landing/TublianOs/TublianOsContext";

function App() {
  ReactGA.initialize("UA-192276161-1");
  const userCache = userProfile(localStorage);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [eightyTwentyPrompt, setEightyTwentyPrompt] = useState(null);

  const [newNotification, setNewNotification] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const fetchNotifications = async (loading) => {
    if (loading) {
      setLoadingNotifications(true);
    }
    const result = await ProfileEndpoint.getNotifications();
    setNewNotification(result.notifications);
    if (loading) {
      setLoadingNotifications(false);
    }
    // console.log("notification-result", result.notifications);
  };

  const connectionNotifications =
    newNotification?.filter(
      (connectionNotification) =>
        connectionNotification?.type === "ConnectionRequest"
    ) || [];

  const pairRequestNotifications =
    newNotification?.filter(
      (pairRequestNotification) =>
        pairRequestNotification?.type === "PairingRequest"
    ) || [];

  // console.log("pairRequestNotification", pairRequestNotifications);

  const pairRequestCount =
    pairRequestNotifications?.filter((count) => count?.read === false) || [];

  const connectionCount =
    connectionNotifications?.filter((count) => count?.read === false) || [];

  useEffect(() => {
    if (userCache.token()) {
      (async () => {
        try {
          const userResponse = await FT.getAuthorizedUser();
          if (userResponse.userId) {
            userHasAuthenticated(true);
            setUser(userResponse);
            fetchNotifications();
          }
        } catch {
          userCache.clear();
        }

        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, []); // eslint-disable-line

  return (
    <ThemeProvider>
      <AppContext.Provider
        value={{
          isAuthenticated,
          loading,
          userHasAuthenticated,
          user,
          setUser,
          newNotification,
          fetchNotifications,
          loadingNotifications,
          eightyTwentyPrompt,
          setEightyTwentyPrompt,
          connectionNotifications,
          pairRequestNotifications,
          pairRequestCount,
          connectionCount,
        }}
      >
        <ChatBotProvider>
          <TublianOsProvider>
            <Routes />
          </TublianOsProvider>
        </ChatBotProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
