import { Fragment, useState, Dispatch, SetStateAction, useMemo } from "react";
import {
  Box,
  Divider,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import CustomButton from "../../Common/CustomButton";
import { useStyles } from "./style";
import ProgressBar from "../../Common/progressBar";
import ResourcesCard from "./ResourcesCard";
import FeedSlider from "../../UserHome/Base/FeedSlider";
import goldMedal from "../../../images/goldMedal.png";
import silverMedal from "../../../images/silverMedal.png";
import bronzeMedal from "../../../images/bronzeMedal.png";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import OverlayModal from "../../Common/OverlayModal";
import StatusNotification from "../../Common/StatusNotification";
import TextUtils from "../../../libs/TextUtils";
import RoadmapBanner from "../../Common/RoadmapBanner";
import DetailedCustomTooltip from "../../Common/DetailedCustomTooltip";

interface IsubmitInputProps {
  challengeLink: string;
  setChallengeLink: Dispatch<SetStateAction<string>>;
  isChallengeLinkValid: boolean;
  submissionLinkText: string;
}

const handleSubmit = async (
  id: string,
  status: string,
  setShowMessage: any,
  setSuccessMessage: any,
  challengeLink: string,
  details: string
) => {
  if (status === "not started") {
    await ProfileEndpoint.startChallenge(id);
    setSuccessMessage(
      "Challenge is successfully started. Reach out on Discord if you have any questions. All the best."
    );
    setShowMessage(true);
  }
  if (status === "started" && challengeLink && challengeLink.length > 0) {
    await ProfileEndpoint.submitChallenge(id, {
      link: challengeLink,
      details: details,
    });
    setSuccessMessage(
      "Thanks for the submission. We will review all the submissions and annouce the winners shortly."
    );
    setShowMessage(true);
  }
  // console.log(challengeLink);
};

const ActionBtn = ({
  setShowMessage,
  setSuccessMessage,
  challengeLink,
  details,
  challenge,
}: {
  setShowMessage: any;
  setSuccessMessage: any;
  challengeLink: string;
  details: string;
  challenge: any;
}) => {
  if (challenge.challengeStatus === "Coming Soon") {
    return null;
  }

  const mainActionBtnText = () => {
    if (challenge.challengeStatus === "Open") {
      if (challenge.userStatus === "not started") {
        return "Start Challenge";
      }
      if (challenge.userStatus === "started") {
        return "Submit Challenge";
      }
      if (challenge.userStatus === "submitted") {
        return "Submitted";
      }
    } else if (challenge.challengeStatus === "Coming Soon") {
      return null;
    } else {
      return "Challenge Ended";
    }
  };

  return (
    <CustomButton
      type="primary"
      disabled={
        challenge.userStatus === "submitted" ||
        challenge.challengeStatus === "Closed"
      }
      size="medium"
      onClick={() =>
        handleSubmit(
          challenge.id,
          challenge.userStatus,
          setShowMessage,
          setSuccessMessage,
          challengeLink,
          details
        )
      }
      children={mainActionBtnText()}
    />
  );
};

const SubmitInput = ({
  challengeLink,
  setChallengeLink,
  isChallengeLinkValid,
  submissionLinkText,
}: IsubmitInputProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.submissionLinkWrap}>
      <TextField
        variant="outlined"
        className={classes.submissionLink}
        value={challengeLink}
        error={challengeLink.length > 0 && !isChallengeLinkValid}
        InputProps={{
          classes: {
            notchedOutline:
              challengeLink.length > 0 && !isChallengeLinkValid
                ? classes.errorOutline
                : classes.notchedOutline,
          },
        }}
        helperText={
          challengeLink.length > 0 &&
          !isChallengeLinkValid &&
          "Not Valid. " + submissionLinkText
        }
        onChange={(e) => setChallengeLink(e.target.value)}
        placeholder={submissionLinkText}
      />
    </Box>
  );
};

const ChallengeCard = ({ challenge }: any) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [challengeLink, setChallengeLink] = useState("");
  const [details, setDetails] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const isChallengeLinkValid = useMemo(() => {
    return challenge.submissionLinkValidator(challengeLink);
  }, [challenge, challengeLink]);

  const isDetailsValid = useMemo(() => {
    if (challenge.needsDetails) {
      return challenge.detailsValidator(details);
    }
  }, [challenge, details]);

  return (
    <Box className={classes.main}>
      <OverlayModal isPreviewOpen={showMessage}>
        <StatusNotification
          status="success"
          statusMessage={successMessage}
          cancelBtnName="Close"
          cancelBtnHandleClick={() => window.location.reload()}
        />
      </OverlayModal>
      <Box className={classes.backBtn}>
        <CustomButton
          size="small"
          type="tertiary"
          onClick={() => window.history.back()}
        >
          <ArrowBackOutlined style={{ marginRight: 8 }} />
          Back
        </CustomButton>
      </Box>

      <Box className={classes.headerWrap}>
        <Box className={classes.title}>
          {TextUtils.capsFirstLetter(challenge?.title)}

          {challenge.challengeStatus !== "Coming Soon" && (
            <>
              <span
                className={`${classes.status} ${
                  challenge.challengeStatus === "Open"
                    ? classes.ongoing
                    : classes.ended
                }`}
              />
              <Typography className="statusText">
                {challenge.challengeStatus}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Typography className={`${classes.emphDetails} ${classes.asideText}`}>
        <span>
          {challenge?.subTitle
            ? `(${challenge.subTitle})`
            : "(Build real world experience series)"}
        </span>
      </Typography>
      <Typography className={`${classes.emphDetails} ${classes.asideText}`}>
        Status:
        <span>
          {challenge.challengeStatus === "Coming Soon"
            ? "Coming Soon"
            : TextUtils.capsFirstLetter(challenge.userStatus)}
        </span>
      </Typography>

      <Box className={classes.actionRowDesktop}>
        <Box
          style={{
            width: "100%",
          }}
        >
          {challenge.challengeStatus === "Open" &&
            challenge.userStatus !== "not started" && (
              <>
                <SubmitInput
                  challengeLink={challengeLink}
                  setChallengeLink={setChallengeLink}
                  isChallengeLinkValid={isChallengeLinkValid}
                  submissionLinkText={challenge.submissionLinkText}
                />
                {challenge.needsDetails && (
                  <SubmitInput
                    challengeLink={details}
                    setChallengeLink={setDetails}
                    isChallengeLinkValid={isDetailsValid}
                    submissionLinkText={challenge.detailsText}
                  />
                )}
              </>
            )}
          {challenge.isPublic ? (
            <CustomButton
              type="primary"
              size="medium"
              onClick={() => window.open("/welcome", "_self")}
            >
              Login to Participate
            </CustomButton>
          ) : (
            <ActionBtn
              setShowMessage={setShowMessage}
              setSuccessMessage={setSuccessMessage}
              challengeLink={challengeLink}
              details={details}
              challenge={challenge}
            />
          )}
        </Box>
      </Box>

      <Divider className={classes.divider} style={{ margin: "28px 0" }} />

      <Box className={classes.contentWrap}>
        <Box className={classes.mainContent}>
          <Box className={classes.bodyContainer}>
            {challenge.body.map(
              (
                {
                  header,
                  body,
                  emphasized,
                }: { header: string; body: string; emphasized: boolean },
                i: number
              ) => (
                <div
                  key={i}
                  className={`${classes.bodySegment} ${
                    emphasized ? classes.emphasizedBodySegment : ""
                  }`}
                >
                  <Typography variant="h5">{header}</Typography>
                  <Box dangerouslySetInnerHTML={{ __html: body }} />
                </div>
              )
            )}

            {challenge?.resources?.length > 0 && (
              <Box className={classes.bodySegment}>
                <Typography variant="h5">
                  Resources to help with the challenge
                </Typography>
                {/* <Box>
                  We have provided a list of recommended open source projects
                  for you to contribute to, along with resources and
                  documentation to help you get started.
                </Box> */}
                <FeedSlider slidesToShow={1} showDots={isMobile}>
                  {challenge?.resources?.map((details: any, i: number) => (
                    <Fragment key={i}>
                      <ResourcesCard {...details} />
                    </Fragment>
                  ))}
                </FeedSlider>
              </Box>
            )}

            {challenge?.additionalResources?.length > 0 && (
              <Box className={classes.bodySegment}>
                <Typography variant="h5">Additional resources</Typography>
                {challenge?.additionalResources?.map(
                  (
                    {
                      title,
                      link,
                      description,
                    }: { title: string; link: string; description: string },
                    i: number
                  ) => (
                    <Fragment key={i}>
                      <a href={link}>{title}</a>
                      <Box>{description}</Box>
                    </Fragment>
                  )
                )}
              </Box>
            )}

            {challenge?.postScriptum?.length > 0 && (
              <Box className={classes.bodySegment}>
                {challenge?.postScriptum?.map((el: string, i: number) => (
                  <Box key={i} dangerouslySetInnerHTML={{ __html: el }} />
                ))}
              </Box>
            )}
          </Box>
        </Box>

        {/* Shows on Desktop view */}
        <Box className={classes.asideWrap}>
          <Box className={classes.asideContent} style={{ marginBottom: 18 }}>
            {!isMobile && (
              <DetailedCustomTooltip
                tooltipId="challenge-card"
                title={
                  <Box>
                    <Typography component="h4">Estimated Streetcred</Typography>
                    <Typography>
                      This number tells you how much streetcred you are likely
                      to gain from completing this challenge. Keep in mind,
                      though, that the actual amount could be different.
                    </Typography>
                  </Box>
                }
              >
                <Typography
                  sx={{ cursor: "help" }}
                  className={`${classes.emphDetails} ${classes.asideText}`}
                >
                  Est. score: <span>{challenge.score.toFixed(2)}</span>
                </Typography>
              </DetailedCustomTooltip>
            )}
            <StyledDivider />

            <Typography
              className={`${classes.emphDetails} ${classes.asideText}`}
            >
              No. of participants:
              <span>{challenge.participantsCnt}</span>
            </Typography>

            <StyledDivider />

            <Box className={classes.healthWrap}>
              <ProgressBar
                startText={`${challenge?.userStatus
                  .charAt(0)
                  .toUpperCase()}${challenge?.userStatus.slice(1)}`}
                current={
                  challenge.userStatus === "submitted"
                    ? 2
                    : challenge.userStatus === "started"
                    ? 1
                    : 0
                }
                max={2}
                margin={12}
                borderRadius={10}
              />
            </Box>

            <StyledDivider />

            {challenge.firstPrize && (
              <Typography
                className={`${classes.emphDetails} ${classes.asideText}`}
              >
                <img
                  className={classes.medals}
                  src={goldMedal}
                  alt="gold medal"
                />{" "}
                <span>{challenge.firstPrize}</span>
              </Typography>
            )}
            {challenge.secondPrize && (
              <Typography
                className={`${classes.emphDetails} ${classes.asideText}`}
              >
                <img
                  className={classes.medals}
                  src={silverMedal}
                  alt="silver medal"
                />{" "}
                <span>{challenge.secondPrize}</span>
              </Typography>
            )}
            {challenge.prizeImage && (
              <img
                src={challenge.prizeImage}
                width="80%"
                alt="Tublian merchandise"
              />
            )}

            {challenge.thirdPrize && (
              <Typography
                className={`${classes.emphDetails} ${classes.asideText}`}
              >
                <img
                  className={classes.medals}
                  src={bronzeMedal}
                  alt="bronze medal"
                />{" "}
                <span>{challenge.thirdPrize}</span>
              </Typography>
            )}

            <StyledDivider />

            <Typography
              className={`${classes.emphDetails} ${classes.asideText}`}
            >
              Duration: <span>{challenge.duration}</span>
            </Typography>

            <Typography
              className={`${classes.emphDetails} ${classes.asideText}`}
            >
              Start: <span>{challenge.start}</span>
            </Typography>

            <Typography
              className={`${classes.emphDetails} ${classes.asideText}`}
            >
              End: <span>{challenge.end}</span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <RoadmapBanner
              mainMsg="Want to learn or upgrade your skills to solve this challenge?"
              secondaryMsg="Create your personalized learning plan."
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengeCard;

const StyledDivider = styled(Divider)({
  backgroundColor: "#414141",
  marginTop: 20,
  marginBottom: 20,
});
