import { Box, Typography, styled } from "@mui/material";
import osText from "../../../images/landing/OsPage/os-text-cutoff.png";
import { useTublianOsContext } from "./TublianOsContext";

const TransformYourSoftware = () => {
  const { handleOpenWaitlistForm } = useTublianOsContext();
  return (
    <CtaSection>
      <CtaColOne>
        <Box>
          <Typography component="h4">
            Imagine a single developer running a billion-dollar software
            company!
          </Typography>
          <Typography component="p">
            In the era of abundant intelligence, take your ambition and multiply
            it by 100. Join the revolution. Let's change how software is built.{" "}
          </Typography>
          <CtaBtnBox onClick={handleOpenWaitlistForm}>
            <CtaButton>Join Waitlist</CtaButton>
          </CtaBtnBox>
        </Box>
      </CtaColOne>
      <CtaColTwo>
        <img src={osText} alt="" />
      </CtaColTwo>
    </CtaSection>
  );
};

export default TransformYourSoftware;

const CtaSection = styled("div")(({ theme }) => ({
  background: "linear-gradient(180deg, #27272A 0%, #19191B 100%)",
  maxWidth: "1072px",
  width: "1072px",
  height: "438px",
  color: "#FEFEFE",
  borderRadius: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  overflow: "hidden",
  [theme.breakpoints.down(768)]: {
    width: "100%",
    height: "729px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 0,
  },
}));

const CtaColOne = styled("div")(({ theme }) => ({
  padding: "40px 18px 40px 40px",
  "&>div": {
    width: "432px",
    "& h4": {
      fontSize: "2.25rem",
      lineHeight: "3.25rem",
      fontWeight: 700,
      marginBottom: "18px",
      [theme.breakpoints.down(768)]: {
        fontSize: "1.5rem",
        lineHeight: "34px",
      },
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      marginBottom: "20px",
    },
  },
  [theme.breakpoints.down(768)]: {
    textAlign: "left",
    height: "388px",
    "&>div": {
      width: "100%",
    },
  },
  "&>p": {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    marginBottom: 20,
  },
}));

const CtaColTwo = styled("div")(({ theme }) => ({
  height: "438px",
  "& img": {
    maxHeight: "438px",
  },
  [theme.breakpoints.down(768)]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    minWidth: "100%",
    "& img": {
      maxHeight: "341.43px",
    },
  },
}));

const CtaBtnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(720)]: {
    marginTop: "20px",
    marginBottom: "60px",
    width: "100%",
  },
}));

const CtaButton = styled("button")(({ theme }) => ({
  background: "linear-gradient(to bottom, #FDD649 0%, #97802C 100%)",
  border: "1px solid #FDD649",
  padding: "8px 32px",
  color: "#FEFEFE",
  borderRadius: 20,
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    background: "#97802C",
  },
  [theme.breakpoints.down(720)]: {
    // width: "100%",
    justifyContent: "center",
    marginTop: 0,
  },
}));
