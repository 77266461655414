// import PageSwitch from "../switch/PageSwitch";
import { RefCode } from "../../../libs/refCode";
import { logEvent } from "../../../libs/amplitude";
import GithubClient from "../../../libs/GithubClient";
import { Box, Typography, styled } from "@mui/material";
import { githubSvg, heroSvg } from "../../UserHome/Base/SVG";

const HeroMessage = ({ messageId }: { messageId: number }) => {
  switch (messageId) {
    case 0:
      return (
        <>
          <h1>
            Online Presence: The Unseen {""}
            <EmphasizedText>Multiplier</EmphasizedText>
          </h1>
          <PaperHeadText>
            With Tublian, let's turn your code into credibility.
          </PaperHeadText>
        </>
      );
    case 1:
      return (
        <>
          <MainText>
            Your Code Speaks. But Does the World {""}
            <EmphasizedText>Hear? </EmphasizedText>
          </MainText>
          <PaperHeadText>With Tublian, make sure they do.</PaperHeadText>
        </>
      );
    case 2:
      return (
        <>
          <MainText>
          Turn every developer into a solo entrepreneur
            {/* Redefine how <SvgIcon component="span">{codeSymbol}</SvgIcon>{" "}
            software is built  */}
            <SvgIcon component="span">{heroSvg}</SvgIcon>
            {/* <EmphasizedText>Story</EmphasizedText> */}
          </MainText>
          <PaperHeadText>
          Redefine how  software is built
          </PaperHeadText>
        </>
      );
    case 3:
      return (
        <>
          <MainText>
            In Tomorrow's World, Online Credibility is {""}
            <EmphasizedText>Capital </EmphasizedText>
          </MainText>
          <PaperHeadText>Accumulate yours with Tublian</PaperHeadText>
        </>
      );
    case 4:
      return (
        <>
          <MainText>
            The Internet Remembers Great {""}
            <EmphasizedText>Developers </EmphasizedText>
          </MainText>
          <PaperHeadText>
            Let Tublian elevate your developer story.
          </PaperHeadText>
        </>
      );
    case 5:
      return (
        <>
          <MainText>
            Great Code Deserves an {""}
            <EmphasizedText>Audience</EmphasizedText>
          </MainText>
          <PaperHeadText>
            With Tublian, ensure your work doesn't go unnoticed.
          </PaperHeadText>
        </>
      );
    default:
      return (
        <>
          <MainText>Scale your software developer career</MainText>
          <PaperHeadText>
            The only platform you need to build your career and be seen
          </PaperHeadText>
        </>
      );
  }
};

const MainTopSec = () => {
  const signInCode = RefCode.code();

  const messageId = Math.floor(Math.random() * 6);

  const handleGithubSignIn = () => {
    logEvent("LandingPage", "public-view", {
      sourceComponent: "joinBtn-mainTopSec",
      messageId: messageId,
    });
    GithubClient.signIn(signInCode);
    return false;
  };

  return (
    <Root>
      <MainContainer>
        <section>
          {/* <div className={classes.switchBtn}>
            <PageSwitch buttonIndex={1} />
          </div> */}
          <MainText>
            <HeroMessage messageId={2} />
            <CtaBtnBox onClick={handleGithubSignIn}>
              <CtaButton>{githubSvg}Join with Github</CtaButton>
            </CtaBtnBox>
          </MainText>
        </section>
      </MainContainer>
    </Root>
  );
};

export default MainTopSec;

const Root = styled("div")(({ theme }) => ({
  maxWidth: "1200px",
  margin: "auto",
  [theme.breakpoints.down(900)]: {
    paddingTop: 16,
  },
  [theme.breakpoints.down(720)]: {
    paddingRight: 16,
    paddingLeft: 16,
  },
}));

const SvgIcon = styled(Box)(({ theme }) => ({
  lineHeight: 0,
  display: "inline-block",
  position: "relative",
  top: "20px",
  [theme.breakpoints.down(1025)]: {
    top: "15px",
    "& svg": {
      width: "60px",
      height: "60px",
    },
  },
  [theme.breakpoints.down(720)]: {
    top: "8px",
    "& svg": {
      width: "32px",
      height: "32px",
    },
  },
}));

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: 112,
  [theme.breakpoints.down(900)]: {
    paddingTop: 76,
  },
}));

const EmphasizedText = styled("span")(({ theme }) => ({
  color: "#EFB81F",
  textDecorationThickness: "0.3vw",
  [theme.breakpoints.down(600)]: {
    fontSize: "2rem",
  },
}));

const CtaButton = styled("button")(({ theme }) => ({
  background: "linear-gradient(to bottom, #FDD649 0%, #97802C 100%)",
  padding: "8px 32px",
  color: "#FEFEFE",
  borderRadius: 20,
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: 40,
  "&:hover": {
    background: "#97802C",
  },
  [theme.breakpoints.down(720)]: {
    width: "100%",
    justifyContent: "center",
    marginTop: 0,
  },
}));

const MainText = styled("h1")(({ theme }) => ({
  width: "874px",
  margin: "auto",
  [theme.breakpoints.down(600)]: {
    width: "100%",
  },
  [theme.breakpoints.down(900)]: {
    maxWidth: "520px",
    width: "100%",
  },
  "&>h1": {
    color: "#fefefe",
    fontWeight: 700,
    fontSize: "68px",
    textAlign: "center",
    lineHeight: "60.77px",
    textTransform: "capitalize",
    [theme.breakpoints.down(1025)]: {
      width: "640px",
      fontSize: "48px",
      lineHeight: "40px",
    },
    [theme.breakpoints.down(720)]: {
      fontSize: "24px",
      lineHeight: "46px",
      width: "100%",
    },
  },
}));

const CtaBtnBox = styled("div")(({ theme }) => ({
  margin: "4px 0 120px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down(1025)]: {
    marginBottom: "112px",
  },
  [theme.breakpoints.down(720)]: {
    marginTop: "20px",
    marginBottom: "60px",
    width: "100%",
  },
}));

const PaperHeadText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "32px",
  margin: "auto",
  textAlign: "center",
  marginTop: 20,
  color: "#fefefe",
  // width: 407,

  [theme.breakpoints.down(720)]: {
    fontSize: 16,
    lineHeight: "24px",
    width: 300,
    marginTop: 16,
  },

  [theme.breakpoints.down(320)]: {
    width: "100%",
  },
}));
