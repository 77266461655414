import { useEffect } from "react";
// import Header from "../components/Landing/Header";
import MainTopSec from "../components/Landing/DevelopersPage/MainTopSec";
import { styled } from "@mui/system";
import heroImg from "../images/landing/heroImg.svg";
import heroImgMobile from "../images/landing/heroImgMobile.svg";
import { logEvent } from "../libs/amplitude";
import Footer from "../components/Landing/Footer";
import OurPlan from "../components/Landing/DevelopersPage/OurPlan";
import OpenSourceJourney from "../components/Landing/DevelopersPage/OpenSourceJourney";
// import VideoSec from "../components/Landing/DevelopersPage/VideoSec";
// import DevsTestimonial from "../components/Landing/DevelopersPage/DevsTestimonial";
import glassEffect from "../images/landing/glasseffect.png";
import VideoSec from "../components/Landing/DevelopersPage/VideoSec";
import Features from "../components/Landing/DevelopersPage/Features";
import NewHeader from "../components/Landing/NewHeader";

const LandingPage = () => {
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        logEvent("LandingPage", "public-view", {
          sourceComponent: `scrolledTo-${entry.target.id}`,
        });
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const sourcecomponentIds = [
      "mainTopSec",
      "videoSec",
      "affiliatedCompaniesSec",
      "techJourneyEmpowermentSec",
      "becomeFutureReadySec",
      "devsBuildingStreetcredSec",
      "ourPlanSec",
      "devsTestimonialSec",
      "openSourceJourneySec",
    ];

    sourcecomponentIds.forEach((id) => {
      const target = document.getElementById(id);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      sourcecomponentIds.forEach((id) => {
        const target = document.getElementById(id);
        if (target) {
          observer.unobserve(target);
        }
      });
    };
  }, []);

  return (
    <Landing>
      <Blur style={{ backgroundColor: "rgba(217, 217, 217, 1)" }}></Blur>
      <TopSection>
        <HeaderCon>
          <NewHeader />
        </HeaderCon>
        <div id="mainTopSec">
          <MainTopSec />
        </div>
        <ContainerVid>
          <div id="videoSec">
            <VideoSec />
          </div>
        </ContainerVid>
      </TopSection>

      <Container>
        <div id="ourPlanSec">
          <OurPlan />
        </div>
      </Container>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <Image src={glassEffect} alt="" />
        <FutureReadyCon>
          <div id="becomeFutureReadySec">
            <Features />
          </div>
        </FutureReadyCon>
      </div>

      {/* <div id="devsTestimonialSec">
        <DevsTestimonial />
      </div> */}
      <ContainerOS>
        <div id="openSourceJourneySec">
          <OpenSourceJourney />
        </div>
      </ContainerOS>
      <FooterCon>
        <Footer />
      </FooterCon>
    </Landing>
  );
};

export default LandingPage;

const Landing = styled("div")(({ theme }) => ({
  scrollBehavior: "smooth",
  backgroundColor: "#111111",
  position: "relative",
  overflow: "hidden",
  width: "100%",
}));

const Blur = styled("div")({
  position: "absolute",
  width: 762,
  height: 34,
  borderRadius: "0 0 10px 10px",
  background: "#D9D9D9",
  filter: "blur(200px)",
  pointerEvents: "none",
  left: "339px",
  top: 0,
});

const Container = styled("div")(({ theme }) => ({
  maxWidth: 1440,
  position: "relative",
  width: "calc(100% - 128px)",
  margin: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "100%",
  },
  [theme.breakpoints.down(720)]: {
    padding: "0 16px",
  },
}));

const ContainerVid = styled(Container)(({ theme }) => ({
  width: "calc(100% - 128px)",
  [theme.breakpoints.down(1025)]: {
    width: "calc(100% - 128px)",
  },
  [theme.breakpoints.down(900)]: {
    padding: 16,
    width: "100%",
  },
}));

const ContainerOS = styled("div")(({ theme }) => ({
  maxWidth: 1200,
  position: "relative",
  width: "calc(100% - 112px)",
  margin: "auto",
  [theme.breakpoints.down(800)]: {
    width: "100%",
  },
}));

const FutureReadyCon = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down(800)]: {
    padding: "0 16px",
  },
}));

const Image = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
});

const TopSection = styled("div")(({ theme }) => ({
  background: `url(${heroImg}) no-repeat center 0px`,
  backgroundSize: "cover",
  backgroundBlendMode: "multiply",
  position: "relative",
  width: "100%",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(600)]: {
    background: `url(${heroImgMobile}) no-repeat center 16px`,
    backgroundSize: "100 100%",
    backgroundPosition: `center 0px`,
  },
}));

const HeaderCon = styled("div")({
  margin: "auto",
  maxWidth: "1440px",
  position: "fixed",
  width: "100%",
  top: 0,
  background: "rgba(17, 17, 17, 0.05)",
  backdropFilter: "blur(20px) !important",
  zIndex: 1000,
});

const FooterCon = styled("div")({
  zIndex: "999",
});
