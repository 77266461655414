import { CSSProperties, ReactNode, SVGProps } from "react";

export interface ICustomButtonProps {
  onClick?: any;
  disabled?: boolean | undefined;
  children: ReactNode;
  style?: CSSProperties;
  size?:
    | "xl"
    | "large"
    | "medium"
    | "small"
    | "xSmall"
    | "landing"
    | "neutral"
    | undefined;
  type?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "neutral"
    | "company"
    | "auth"
    | "onBoarding" // Scrap this
    | "grey"
    | "greyOutlined"
    | undefined;
  nativeType?: "button" | "reset" | "submit" | undefined;
  loading?: boolean;
  loadingText?: string;
  preImg?: string | object | ReactNode | SVGProps<SVGElement>;
  postImg?: string | object | ReactNode | SVGProps<SVGElement>;
  loadingSpinner?: ReactNode;
}

export const getSizeStyles = (size: string, type: string) => {
  switch (size) {
    case "xl":
      return {
        // padding: "36px 8px",
        padding: type === "tertiary" ? "0" : "36px 0",
        width: 400,
        fontSize: "18px",
        lineHeight: "30px",
      };
    case "large":
      return {
        padding: type === "tertiary" ? "0" : "12px 32px",
        fontSize: "16px",
        lineHeight: "24px",
      };
    case "medium":
      return {
        padding: type === "tertiary" ? "0" : "8px 16px",
        fontSize: "16px",
        lineHeight: "24px",
      };
    case "small":
      return {
        padding: type === "tertiary" ? "0" : "4px 12px",
        fontSize: "14px",
        lineHeight: "20px",
      };
    case "xSmall":
      return {
        padding: type === "tertiary" ? "0" : "4px 10px",
        fontSize: "12px",
        lineHeight: "16px",
      };
    case "landing":
      return {
        padding: type === "tertiary" ? "0" : "8px 32px",
        fontSize: "16px",
        lineHeight: "24px",
      };
    case "neutral":
      return {
        padding: type === "tertiary" ? "0" : "6px 6px",
        fontSize: "14px",
        lineHeight: "20px",
      };
    default:
      return {
        padding: type === "tertiary" ? "0" : "12px 32px",
        fontSize: "16px",
        lineHeight: "24px",
      };
  }
};
