import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    position: "fixed",
    top: 0,
    left: 0,
    width: 234,
    height: "100vh",
    background: "#121212",
    // paddingTop: 32,
    paddingLeft: 16,
    zIndex: 99, //Temporary
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down(1024)]: {
      width: 85,
      padding: "12px 16px 0",
    },
    [theme.breakpoints.down(769)]: {
      top: 0,
      bottom: "unset",
      width: "100%",
      height: 56,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "12px 16px",
      borderRadius: "0",
      background: "#121212",
    },
  },
  sidebarInner: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1024)]: { alignItems: "center" },
    [theme.breakpoints.down(769)]: {
      position: "fixed",
      alignItems: "normal",
      zIndex: 999,
      top: "0",
      left: "8vw",
      height: "100vh",
      width: "95vw",
      padding: "20px 40px 20px 20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      gap: 4,
      backgroundColor: "#1e1e1e",
      transform: "translateY(-100vh)",
    },
  },
  logoWrapper: {
    display: "flex",
    gap: 23,
    marginBottom: 28,
    alignItems: "center",
    [theme.breakpoints.down(1024)]: {
      gap: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down(769)]: {
      marginBottom: 0,
      justifyContent: "space-between",
    },
  },
  logoWrapper2: {
    [theme.breakpoints.down(1024)]: {
      gap: 0,
      marginBottom: 20,
    },
    [theme.breakpoints.down(769)]: {
      marginBottom: 24,
      alignItems: "center",
    },
  },
  logo: {
    height: 21,
    width: 99,

    [theme.breakpoints.down(1024)]: {
      display: "block",
      height: 32,
      width: 28,
    },
  },
  sidebarUnder: {
    height: 56,
    width: "100%",
    display: "block",
    position: "absolute",
    left: "4.1%",
    zIndex: 99,
    // borderRadius: "20px 20px 0 0",
    background: "#121212",
    [theme.breakpoints.up(769)]: {
      display: "none",
    },
  },
  navLinkWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    paddingBottom: 4,
    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down(1024)]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 8,
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down(769)]: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
      padding: 0,
      margin: 0,
      fontSize: 10,
      lineHeight: "12.76px",
      // width: "auto",
      justifyContent: "space-between",
      alignItems: "start",
    },
  },
  sidebarLink: {
    display: "flex",
    padding: "4px 0px 4px 16px",
    justifyContent: "flex-start",
    alignItems: "center !important",
    width: 172,
    gap: 8,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "1.5rem",
    color: "#888",
    borderRadius: "10px",
    textDecoration: "none",
    "-webkit-transition": "all 0.2s ease-in-out 0s",
    "-moz-transition": "all 0.2s ease-in-out 0s",
    transition: "all 0.2s ease-in-out 0s",
    "& > svg": {
      width: 24,
      height: 24,
    },
    "&:hover": {
      background: "#292929",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
    [theme.breakpoints.down(1024)]: {
      width: 42,
      height: 42,
      display: "flex",
      justifyContent: "center",
      borderRadius: 10,
      padding: "0px 0px 0px 0px",
    },
    [theme.breakpoints.down(769)]: {
      display: "flex",
      padding: "4px 0px 4px 16px",
      justifyContent: "flex-start",
      alignItems: "center !important",
      width: 172,
      gap: 8,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "1.5rem",
      color: "#888",
      borderRadius: "10px",
      textDecoration: "none",
      "-webkit-transition": "all 0.2s ease-in-out 0s",
      "-moz-transition": "all 0.2s ease-in-out 0s",
      transition: "all 0.2s ease-in-out 0s",
      "& > svg": {
        width: 24,
        height: 24,
      },
      "&:hover": {
        background: "#292929",
      },
      "& svg": {
        width: 18,
        height: 18,
      },
    },
    // [theme.breakpoints.down(372)]: {
    //   width: 60,
    // },
  },
  sidebar_link_active: {
    color: "#fefefe",
    backgroundColor: "#292929",
    [theme.breakpoints.down(769)]: { width: "100%" },
    // [theme.breakpoints.down(769)]: {
    //   backgroundColor: "transparent",
    //   color: "#fefefe",
    //   width: "auto",
    // },
  },
  hideOnMobile: {
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  hideOnTab: {
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
    [theme.breakpoints.down(769)]: {
      display: "block",
    },
  },

  hideOnTabDown: {
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },

  showOnMobile: {
    display: "none",
    [theme.breakpoints.down(769)]: {
      display: "flex",
    },
  },
  sidebar_link_text: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& svg": {
      marginLeft: 16,
    },
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
    [theme.breakpoints.down(769)]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  hamMenu: {
    display: "none",
    [theme.breakpoints.down(769)]: {
      display: "block",
      cursor: "pointer",
    },
  },
  responsiveNav: {
    transform: "none",
    zIndex: 9999,
  },

  closeNav: {
    display: "none",
    [theme.breakpoints.down(769)]: {
      padding: 3,
      display: "block",
      marginBottom: 12,
      // position: "absolute",
      // top: 0,
      // left: 0,
    },
  },
  //SidebarMoreMenu Styles
  button: {
    // color: "#888",
    padding: 0,
    "& span": {
      display: "flex",
      flexDirection: "column",
      gap: 4,
      width: 60,
      height: 42,
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: "12.76px",
    },
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  menu: {
    color: "#888",
    position: "relative",
  },
  menuPaper: {
    width: 283,
    backgroundColor: "#272727",
    display: "block",
    position: "fixed",
    bottom: 0,
    right: 0,
    [theme.breakpoints.up(701)]: {
      display: "none",
    },
  },
  menuList: {},
  menuItem: {
    color: "#888",
    padding: "12px 20px",
    marginBottom: 14,
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
    "& .icons": {
      color: "#888",
      minWidth: "auto",
      "& svg": {
        width: 24,
        height: 24,
      },
    },
  },
  menuItemActive: {
    color: "#fefefe",
    padding: "12px 20px",
    marginBottom: 14,
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
    "& .icons": {
      color: "#fefefe",
      minWidth: "auto",
      "& svg": {
        width: 24,
        height: 24,
      },
    },
  },
  hideTosMenu:{
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
    [theme.breakpoints.down(960)]: {
      display: "block",
    },
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
  closeBtn: {
    padding: 0,
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 16,
    "& .MuiButton-label": {
      justifyContent: "flex-start",
    },
  },
  linkText: {
    marginLeft: 16,
  },
  notificationDot: {
    position: "absolute",
    top: "-1px",
    right: "1px",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#F26663",
    border: "none",
  },
}));

