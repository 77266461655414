import ProfileEndpoint from "./ProfileEndpoint";
import { userProfile } from "./UserProfile";

const ft_lambda_url = process.env.REACT_APP_FT_BACKEND_API_BASE;
const lambda_headers = {
  "x-api-key": process.env.REACT_APP_FT_BACKEND_API_KEY,
  Accept: "application/json",
  "Content-Type": "application/json",
};

const getLambdaHeaders = () => {
  const headers = { ...lambda_headers };
  return headers;
};

const getHeaders = () => {
  const headers = { ...lambda_headers };

  const jwt = userProfile(localStorage).token();
  if (jwt) headers.Authorization = `Bearer ${jwt}`;

  return headers;
};

const getLambdaUrl = (path) => {
  return `${ft_lambda_url}${path}`;
};

const FT = {
  async addToJournal(
    userId,
    id,
    title,
    link,
    image,
    source,
    summary,
    tag,
    contentType,
    eventType
  ) {
    var response = await fetch(getLambdaUrl("/user/journal/add"), {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        documentId: id,
        title: title,
        link: link,
        image: image,
        source: source,
        summary: summary,
        tag: tag,
        eventType: eventType,
        contentType: contentType,
      }),
    });

    return response.json();
  },
  async contactUs(name, email) {
    const body = {
      name: name,
      email: email,
    };
    var response = await fetch(getLambdaUrl(`/ft/contactUs`), {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    return response.json();
  },
  async recentlySearchProjects(userId) {
    const url = new URL(getLambdaUrl(`/ft/events/recent/projects`));
    url.searchParams.append("userId", userId);
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },
  async recentlySearchQuestions(userId) {
    const url = new URL(getLambdaUrl(`/ft/events/recent/questions`));
    url.searchParams.append("userId", userId);
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },
  async saveUserEvent(userId, eventId, eventType, payload) {
    const body = {
      userId: userId,
      eventId: eventId,
      eventType: eventType,
      payload: payload,
    };
    var response = await fetch(getLambdaUrl(`/ft/user/event/save`), {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    return response.json();
  },
  async recentUserEvents(userId) {
    const url = new URL(getLambdaUrl(`/ft/events/recent`));
    url.searchParams.append("userId", userId);
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },
  async postToJournal(userId, post) {
    var response = await fetch(getLambdaUrl("/user/journal/post"), {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        userId,
        post,
      }),
    });

    return response.json();
  },
  async removeFromJournal(userId, id) {
    var response = await fetch(getLambdaUrl("/user/journal/remove"), {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        documentId: id,
      }),
    });

    return response.json();
  },

  async updateUserPref(userId, pref) {
    var response = await fetch(getLambdaUrl(`/ft/user/pref/${userId}`), {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(pref),
    });

    return response.json();
  },

  async getAuthorizedUser() {
    var response = await fetch(getLambdaUrl("/auth/user"), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async getDummyUsers() {
    var response = await fetch(getLambdaUrl("/admin/dummies"), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async impersonate(userId) {
    var response = await fetch(getLambdaUrl(`/admin/impersonate/${userId}`), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async unimpersonate() {
    var response = await fetch(getLambdaUrl("/admin/unimpersonate"), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async createPaymentIntent(priceId, currency) {
    const url = getLambdaUrl("/ft/stripe/createPaymentIntent");

    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ priceId, currency }),
    });

    return response.json();
  },

  async getCreditPaymentInfo(action, queryParam) {
    const url = getLambdaUrl(
      `/ft/stripe/info/${action}${queryParam ? `?${queryParam}` : ""}`
    );

    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
    });

    return response.json();
  },

  // async createPaymentIntent(priceId, currency) {
  //   const url = "https://tublian-stripe-server.glitch.me/create-payment-intent";
  //   const payload = JSON.stringify({
  //     priceId,
  //     currency,
  //     username: userProfile(localStorage).userId(),
  //   });

  //   console.log("🚀 ~ createPaymentIntent ~ payload:", payload)

  //   const response = await fetch(url, {
  //     method: "POST",
  //     // headers: getHeaders(),
  //     headers: { "Content-Type": "application/json" },
  //     body: payload,
  //   });

  //   console.log("res", response);

  //   return response.json();
  // },

  // async getCreditPaymentInfo(action, queryParam) {
  //   let url = `https://tublian-stripe-server.glitch.me/${action}${
  //     queryParam ? `?${queryParam}` : ""
  //   }`;

  //   if (action === "productInfo") {
  //     const match = queryParam.match(/productId=([^&]*)/);
  //     let productId = match ? match[1] : null;
  //     url = `https://tublian-stripe-server.glitch.me/${action}/${productId}/USD`;
  //   }

  //   if (action === "paymentHistoryInfo") {
  //     url = `https://tublian-stripe-server.glitch.me/${action}/${userProfile(
  //       localStorage
  //     ).userId()}`;
  //   }

  //   const response = await fetch(url, {
  //     method: "GET",
  //   });

  //   return await response.json();
  // },

  async referFriend() {
    var response = await fetch(getLambdaUrl(`/generate/invite/friend`), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async createAccount(
    userId,
    name,
    profilePicUrl,
    bio,
    emailAddress,
    roles,
    bootstrapItems,
    referenceUserId,
    location,
    additionaSources,
    openToInternship
  ) {
    // console.log("Selected Bootstrap items ", bootstrapItems);
    var response = await fetch(getLambdaUrl("/ft/create"), {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        name: name,
        bio: bio,
        emailAddress: emailAddress,
        roles: roles,
        bootstrapItems: bootstrapItems,
        reference: referenceUserId,
        location: location,
        openToInternship,
      }),
    });
    await ProfileEndpoint.addProfileForAccount(
      userId,
      name,
      profilePicUrl,
      bio,
      roles,
      location,
      additionaSources
    );

    return response.json();
  },

  async validateInvitationCode(invitationCode) {
    if (invitationCode === "b1ee83896c963a") {
      //using default code so no need to check
      return { isValid: true, emailAddress: "", reference: "" };
    }
    var response = await fetch(
      getLambdaUrl("/validate/invite/" + invitationCode),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async markInviteCodeUsed(invitationCode) {
    var response = await fetch(
      getLambdaUrl("/markUsed/invite/" + invitationCode),
      {
        method: "POST",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getDeveloperRoles() {
    var response = await fetch(getLambdaUrl("/developer/roles"), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async get(userId, params) {
    const url = new URL(getLambdaUrl("/ft/user/" + userId));
    if (params.avatar) url.searchParams.append("avatar", params.avatar);
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async update(user) {
    var response = await fetch(getLambdaUrl("/ft/user/" + user.userId), {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(user),
    });
    return response.json();
  },

  async recommendConnections(userId) {
    const url = new URL(
      getLambdaUrl(`/ft/user/recommend/connections/${userId}`)
    );
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async recommendConnectionsForOnboarding(userId, interestAreas) {
    const url = new URL(
      getLambdaUrl(
        `/ft/user/new/recommend/connections/${userId}?interestAreas=${interestAreas}`
      )
    );
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async connections(userId) {
    const url = new URL(getLambdaUrl(`/ft/user/get/connections/${userId}`));
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async connectDevelopers(userId, otherId) {
    const url = new URL(getLambdaUrl(`/ft/user/connection/request/new`));
    // console.log(">>>>> url ", url);
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        otherId: otherId,
      }),
    });
    return response.json();
  },

  async updateReadConnections(userId) {
    const response = await fetch(
      getLambdaUrl(`/ft/user/connection/update/read`),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          userId: userId,
        }),
      }
    );
    return response.json();
  },

  async acceptConnectionRequest(userId, otherId, notificationId) {
    const url = new URL(getLambdaUrl(`/ft/user/connection/request/accept`));
    // console.log(">>>>> url ", url);
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        otherId: otherId,
        notificationId: notificationId,
      }),
    });
    return response.json();
  },
  async declineConnectionRequest(userId, notificationId) {
    const url = new URL(getLambdaUrl(`/ft/user/connection/request/decline`));
    // console.log(">>>>> url ", url);
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        notificationId: notificationId,
      }),
    });
    return response.json();
  },

  async isDevelopersConnected(otherId) {
    const url = new URL(getLambdaUrl(`/ft/user/check/connection/${otherId}`));
    // console.log(">>>>> url ", url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async getUserJournalForDates(userId, startDate, endDate) {
    const url = new URL(getLambdaUrl(`/user/journal/entries/${userId}`));
    url.searchParams.append("startDate", startDate.toISOString());
    url.searchParams.append("endDate", endDate.toISOString());
    // console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async getUserFeedForDate(params) {
    const response = await fetch(
      getLambdaUrl(`/user/feed/v2/${params.userId}/${params.date}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getUserBootstrapSample(interestAreas) {
    const response = await fetch(
      getLambdaUrl(`/user/feed/v2/bootstrap/sample`),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          interestAreas: interestAreas.join(),
        }),
      }
    );
    return response.json();
  },

  async getPopularFeedForDate(params) {
    const response = await fetch(
      getLambdaUrl(`/popular/feed/v2/${params.userId}/${params.date}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getFeatureForDate(params) {
    const response = await fetch(
      getLambdaUrl(`/popular/top/v2/${params.userId}/${params.date}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getInterestingFeedForDate(params) {
    const response = await fetch(
      getLambdaUrl(`/interesting/feed/v2/${params.userId}/${params.date}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getRawFeedForDate(params) {
    const url = new URL(getLambdaUrl(`/user/feed/tubliBot/${params.date}`));
    url.searchParams.append("cursor", params.cursor);
    url.searchParams.append("userId", params.userId);

    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async getPodcastFeed(params) {
    const response = await fetch(
      getLambdaUrl(`/user/podcast/feed/${params.userId}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json();
  },

  async getTalks(params) {
    const url = new URL(getLambdaUrl(`/user/video/feed/${params.userId}`));
    url.searchParams.append("feedType", "talks");
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },

  async getVblogs(params) {
    const url = new URL(getLambdaUrl(`/user/video/feed/${params.userId}`));
    url.searchParams.append("feedType", "vblogs");
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },
  async getLearningCredits() {
    const url = new URL(getLambdaUrl(`/ft/user/learning/credits`));
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json();
  },
  async updateLearningCredits(newCredits) {
    const url = new URL(getLambdaUrl(`/ft/user/learning/update/credits`));
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        credits: newCredits,
      }),
    });
    return response.json();
  },
  async addToWaitlist(name, email, companyName) {
    const url = getLambdaUrl("/ft/stripe/addToWaitList");
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: getLambdaHeaders(),
        body: JSON.stringify({ name, email, companyName }),
      });

      return response.json();
    } catch (error) {
      console.log("error", error);
    }
  },
};

export default FT;
