import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { DocsData } from "./DocsData";
import { RefObject } from "react";

interface DocsContentsProps {
  sectionRefs: { [key: string]: RefObject<HTMLDivElement> };
}

const DocsContents: React.FC<DocsContentsProps> = ({ sectionRefs }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "71%",
        color: "#fefefe",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        [theme.breakpoints.down(1024)]: { width: "100%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "34px",
            mb: "4px",
          }}
        >
          TOS Developer Agent (Early Access)
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          The Tublian Developer Agent is an autonomous AI tool designed to
          handle end-to-end software development tasks. It covers everything
          from analysis and planning to implementation, allowing developers to
          focus on more critical and creative work.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          Once assigned a task, the agent operates independently, reaching out
          to developers only when it needs assistance or clarification. It can
          even manage legacy codebases, freeing up valuable developer time.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          We're excited to offer this as an early access beta to our community
          members.
        </Typography>
      </Box>
      {DocsData.map((m) => (
        <Box ref={sectionRefs[m.id]} key={m.id}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "32px",
              mb: "12px",
            }}
          >
            {m.header}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            {m.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DocsContents;
