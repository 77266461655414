import { Box, Divider, styled } from "@mui/material";
import { useTheme } from "@mui/system";

interface DocsLinksProps {
  handleScrollToSection: (sectionId: string) => void;
  activeSection: string;
}

const DocsLinks: React.FC<DocsLinksProps> = ({
  handleScrollToSection,
  activeSection,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "sticky",
        top: 24,
        width: "264px",
        height: "fit-content",
        backgroundColor: "#1E1E1E",
        border: "1px solid #292929",
        borderRadius: "20px",
        padding: "20px",
        fontSize: "14px",
        color: "#fefefe",
        lineHeight: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        [theme.breakpoints.down(1024)]: { display: "none" },
      }}
    >
      <p style={{ fontWeight: 700, marginBottom: "4px" }}>Getting Started</p>
      <ol
        style={{
          marginLeft: "20px",
          fontWeight: 400,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "6px",
        }}
      >
        <AnchorList
          isActive={activeSection === "releaseNotes"}
          onClick={() => handleScrollToSection("releaseNotes")}
        >
          Release Notes
        </AnchorList>
        <AnchorList
          isActive={activeSection === "systemRequirements"}
          onClick={() => handleScrollToSection("systemRequirements")}
        >
          System Requirements
        </AnchorList>
        <AnchorList
          isActive={activeSection === "setupGuide"}
          onClick={() => handleScrollToSection("setupGuide")}
        >
          Setup
        </AnchorList>
        <AnchorList
          isActive={activeSection === "faqs"}
          onClick={() => handleScrollToSection("faqs")}
        >
          FAQs
        </AnchorList>
      </ol>
      <Divider sx={{ backgroundColor: "#292929" }} />
      <p style={{ marginTop: "6px" }}>
        Join the{" "}
        <a
          href="https://discord.gg/S6KGq3NR7F"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#fdd649",
          }}
        >
          Tublian Discord Channel
        </a>{" "}
        for more information.
      </p>
    </Box>
  );
};

export default DocsLinks;

const AnchorList = styled("li")<{ isActive: boolean }>(
  ({ isActive, theme }) => ({
    color: isActive ? "#fdd649" : "#fefefe",
    cursor: "pointer",
  })
);
