import emptyProfileTemplate from "./empty-create-account-profile.json";
import helperFunctions from "./helperFunctions";
import { userProfile } from "./UserProfile";
import Bugsnag from "@bugsnag/js";

const { reencodeRoadmapId } = helperFunctions;

const ft_lambda_url = process.env.REACT_APP_PROFILE_BACKEND_API_BASE;
const ft_copilot_url = process.env.REACT_APP_COPILOT_API_BASE;
// console.log("FT lambda url ", ft_lambda_url);

const lambda_headers = {
  "x-api-key": process.env.REACT_APP_PROFILE_BACKEND_API_KEY,
  Accept: "application/json",
  "Content-Type": "application/json",
};

const getHeaders = () => {
  const headers = { ...lambda_headers };
  return headers;
};

const getTokenizedHeaders = () => {
  const headers = { ...lambda_headers };

  const jwt = userProfile(localStorage).token();
  if (jwt) headers.Authorization = `Bearer ${jwt}`;

  return headers;
};

const getLambdaUrl = (path) => {
  return `${ft_lambda_url}${path}`;
};

const userLanguages = (userId) => {
  const selectedLangs = userProfile(localStorage).selectedLanguages();
  const langauges = encodeURIComponent(selectedLangs?.join(","));
  return langauges;
};

const ProfileEndpoint = {
  async saveLearningRoadmap(roadmap) {
    var response = await fetch(getLambdaUrl(`/ft/learning/roadmap/create`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify(roadmap),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async listLearningRoadmaps(roadmap) {
    var response = await fetch(getLambdaUrl(`/ft/learning/roadmap/list`), {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getLearningRoadmap(roadmapId, userId) {
    const url = getLambdaUrl(`/ft/learning/roadmap/get/${userId}/${roadmapId}`);

    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getSharableRoadmapLinks() {
    const url = getLambdaUrl(`/ft/learning/roadmap/sharable/list`);

    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getChallengeStatus(challengeId) {
    var response = await fetch(
      getLambdaUrl(`/ft/challenge/status/${challengeId}`),
      {
        method: "GET",
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async startChallenge(challengeId) {
    var response = await fetch(getLambdaUrl(`/ft/challenge/start`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        challengeId: challengeId,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async submitChallenge(challengeId, solution) {
    var response = await fetch(getLambdaUrl(`/ft/challenge/submit`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        challengeId: challengeId,
        solution: solution,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  googleJobSearchQuery(isRemote) {
    if (isRemote) {
      return "remote junior OR intern developer";
    }
    return "junior OR intern developer";
  },
  googleJobDefaultSearchQuery(isRemote) {
    if (isRemote) {
      return "remote software engineer";
    }
    return "software engineer";
  },

  async getUserLocationOrDefault() {
    let ip;
    try {
      ip = await ProfileEndpoint.getUserLocation();
    } catch (error) {
      console.error("Error fetching IP address:", error);
      ip = "99.147.159.191"; // set default IP address
    }
    return ip;
  },
  async getUserLocation() {
    // 99.147.159.191
    var response = await fetch("https://api.ipify.org/?format=json", {
      method: "GET",
    });
    const address = await response.json();
    return address.ip;
  },
  async getDeveloperProfilePrompt(userId) {
    var response = await fetch(getLambdaUrl(`/ft/profile/prompt/${userId}`), {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async updateGithubToken() {
    const url = new URL(getLambdaUrl(`/ft/profile/update/accessToken`));
    var response = await fetch(url, {
      method: "PUT",
      headers: getTokenizedHeaders(),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async saveDiscord(discord) {
    var response = await fetch(getLambdaUrl(`/ft/profile/add/discord`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify(discord),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async addLinkedinBio(data) {
    var response = await fetch(getLambdaUrl(`/ft/profile/add/linkedin/bio`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify(data),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async addResume(data) {
    var response = await fetch(getLambdaUrl(`/ft/profile/add/linkedin/bio`), {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify(data),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getStreetCredSummary(userId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/creds/summary/${userId}`),
      {
        method: "GET",
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getStreetCredsScore(userId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/creds/score/${userId}`),
      {
        method: "POST",
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  // async getGithubStatsForOnboarding(userId) {
  //   var response = await fetch(
  //     getLambdaUrl(`/ft/profile/calculate/github/creds/score/${userId}`),
  //     {
  //       method: "POST",
  //       headers: getTokenizedHeaders(),
  //     }
  //   );
  //   return response.json().catch((e) => {
  //     Bugsnag.notify(e);
  //     return {};
  //   });
  // },
  async refreshStreetCredsScore(userId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/calculate/creds/score/${userId}`),
      {
        method: "POST",
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async saveCopilotSummary(summary) {
    // console.log("summary", summary);

    var response = await fetch(getLambdaUrl(`/ft/profile/copilot/jobs/add`), {
      method: "POST",
      body: JSON.stringify(summary),
      headers: getTokenizedHeaders(),
    });
    const res = await response.json();
    // console.log("res", res);
    return res;
  },
  async fetchCopilotSummary(userId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/copilot/jobs/search/${userId}`),
      {
        method: "GET",
        headers: getTokenizedHeaders(),
      }
    );
    const res = await response.json();
    return res;
  },
  async getCopilotJobById(jobId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/copilot/jobs/get?jobId=${jobId}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    const res = await response.json();
    return res;
  },
  async recentPublicEvents() {
    const url = new URL(getLambdaUrl(`/ft/standout/public/events`));
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
  async getGithubHacks(userId, index) {
    const languages = userLanguages(userId);
    // console.log(">>>> Languages ", languages);
    const url = new URL(
      getLambdaUrl(
        `/ft/standout/hacks/github/projects/show/${languages}/${index}`
      )
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getGithubHacksByQuery(query) {
    var response = await fetch(`${ft_copilot_url}/project/search`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ query }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getRepoDetailsById(repoIds) {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/github/project/list`),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(repoIds),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getGithubProjectIssueListById(repoId) {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/github/project/issues/${repoId}`),
      {
        method: "GET",
        headers: getTokenizedHeaders(),
        // body: JSON.stringify(repoId),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getGithubProjectIssueListByName(userName, repoName) {
    var response = await fetch(
      getLambdaUrl(
        `/ft/standout/hacks/github/project/find/${userName}/${repoName}`
      ),
      {
        method: "POST",
        headers: getTokenizedHeaders(),
        // body: JSON.stringify(repoId),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getBeginnerFriendlyGithubProjects() {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/github/beginner/projects`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getProjectsByCategories(topics, languages) {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/search/github/projects`),
      {
        method: "POST",
        body: JSON.stringify({
          topics, //array
          languages, //array
        }),
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getIssuesByCategories(userId, category, topics, languages) {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/search/github/issues`),
      {
        method: "POST",
        body: JSON.stringify({
          userId: userId,
          category, //string
          topics, //array
          languages, //array
        }),
        headers: getTokenizedHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getOrCreatePathwayProgress(pathwayId) {
    const url = new URL(getLambdaUrl(`/ft/pathway/progress/${pathwayId}`));
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async updatePathwayProgress(
    pathwayId,
    status,
    progress,
    pathwayType,
    roadmapId
  ) {
    roadmapId = reencodeRoadmapId(roadmapId, pathwayType);

    const url = new URL(getLambdaUrl(`/ft/pathway/update`));
    // console.log("🚀 ~ roadmapId:", roadmapId);
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        pathwayId,
        status,
        progress,
        pathwayType,
        roadmapId,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },

  async updatePathwayTaskProgress(
    pathwayId,
    status,
    progress,
    roadmapId,
    pathwayType
  ) {
    const url = new URL(getLambdaUrl(`/ft/pathway/update/tasks`));
    roadmapId = reencodeRoadmapId(roadmapId, pathwayType);
    // console.log("body", {
    //   pathwayType,
    //   status,
    //   progress,
    //   roadmapId,
    //   pathwayId,
    // });

    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        pathwayType,
        status,
        progress,
        roadmapId,
        pathwayId,
      }),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },

  async getOrCreateCourseProgress(
    courseId,
    sectionNumber,
    learningObjectives,
    phaseContentFilters,
    roadmapId,
    retries = 3
  ) {
    // console.log("🚀 ~ phaseContentFilters:", phaseContentFilters)
    const url = new URL(getLambdaUrl(`/ft/pathway/progress/${courseId}`));
    roadmapId = reencodeRoadmapId(roadmapId);
    for (let i = 0; i < retries; i++) {
      try {
        var response = await fetch(url, {
          method: "POST",
          headers: getTokenizedHeaders(),
          body: JSON.stringify({
            pathwayType: "course",
            phaseContentFilters,
            roadmapId,
            sectionDetails: {
              sectionNumber,
              learningObjectives,
            },
          }),
        });
        return response.json().catch((e) => {
          Bugsnag.notify(e);
          return {};
        });
      } catch (error) {
        Bugsnag.notify(error);
        console.error(`API call failed (${i + 1}/${retries}): ${error}`);
      }
    }
  },

  async getPhaseStatus(roadmapId, retries = 3) {
    const url = new URL(getLambdaUrl(`/ft/pathway/progress/${roadmapId}`));

    for (let i = 0; i < retries; i++) {
      try {
        var response = await fetch(url, {
          method: "POST",
          headers: getTokenizedHeaders(),
          body: JSON.stringify({
            pathwayType: "phaseStatus",
          }),
        });
        return response.json().catch((e) => {
          Bugsnag.notify(e);
          return {};
        });
      } catch (error) {
        Bugsnag.notify(error);
        console.error(`API call failed (${i + 1}/${retries}): ${error}`);
      }
    }
  },

  async deleteRoadmap(roadmapId, returnCredit = true) {
    // console.log("🚀 ~ deleteRoadmap ~ roadmapId:", roadmapId);
    const url = new URL(
      getLambdaUrl(
        `/ft/learning/roadmap/remove/${roadmapId}?returnCredit=${returnCredit}`
      )
    );

    var response = await fetch(url, {
      method: "DELETE",
      headers: getTokenizedHeaders(),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },

  async getRecentUserRoadmap(userId, roadmapId) {
    const queryParams = roadmapId ? `?roadmapId=${roadmapId}` : "";
    const url = getLambdaUrl(`/ft/pathway/user/recent/${userId}${queryParams}`);

    var response = await fetch(url, {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },

  async linkedInSearch(name) {
    const url = new URL(getLambdaUrl(`/ft/web/search`));
    url.searchParams.append("q", `site:linkedin.com ${name}`);
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async bingSearch(query) {
    const url = new URL(getLambdaUrl(`/ft/web/search/${query}`));
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getNotifications() {
    // console.log(">>>> Languages ", languages);
    const url = new URL(getLambdaUrl(`/ft/notifications`));
    var response = await fetch(url, {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
  async updateNotifications(type) {
    const url = new URL(getLambdaUrl(`/ft/notifications/update`));
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        type: type,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
  async getPairingRequests(repoId, issueId, userId) {
    const url = new URL(
      getLambdaUrl(`/ft/standout/hacks/github/project/pairing/requests`)
    );
    var response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        repoId: repoId,
        issueNumber: issueId,
        userId: userId,
      }),

      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
  async getAllPairingRequests() {
    const url = new URL(
      getLambdaUrl(`/ft/standout/hacks/github/pairing/requests`)
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
  async getMyPairingRequests(userId) {
    const url = new URL(
      getLambdaUrl(
        `/ft/standout/hacks/github/project/pairing/requests/user/${userId}`
      )
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async updateReadPairingRequests(userId) {
    const url = new URL(
      getLambdaUrl(
        `/ft/standout/hacks/github/project/pairing/requests/update/${userId}`
      )
    );
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  //ft/standout/hacks/github/project/pairing/requests/user/
  async interestedToPair(userId, requestId) {
    // console.log("accepting request....", requestId, userId, requestId);
    const url = new URL(getLambdaUrl(`/ft/project/pairing/request/interested`));
    var response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        acceptor: userId,
        requestId: requestId,
      }),
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async acceptPairingRequest(userId, requestId, notificationId) {
    // console.log("accepting request....", requestId);
    const url = new URL(getLambdaUrl(`/ft/project/pairing/request/accept`));
    var response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        acceptor: userId,
        requestId: requestId,
        notificationId: notificationId,
      }),
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async declinePairingRequest(userId, requestId, notificationId) {
    // console.log("accepting request....", requestId);
    const url = new URL(getLambdaUrl(`/ft/project/pairing/request/decline`));
    var response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        acceptor: userId,
        requestId: requestId,
        notificationId: notificationId,
      }),
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async createPairingRequest(issueId, repoId) {
    // console.log("Creating pairing request....");
    const url = new URL(getLambdaUrl(`/ft/project/pairing/request/create`));
    var response = await fetch(url, {
      method: "PUT",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        repoId: repoId,
        issueId: issueId,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getGithubIssue(repoId, issueId) {
    const url = new URL(
      getLambdaUrl(
        `/ft/standout/hacks/github/project/issue/${repoId}/${issueId}`
      )
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  // async listGithubHacks(userId) {
  //   const languages = userLanguages(userId);
  //   // console.log(">>>> languages ", languages);
  //   const url = new URL(
  //     getLambdaUrl(`/ft/standout/hacks/github/projects/${languages}`)
  //   );
  //   var response = await fetch(url, {
  //     method: "GET",
  //     headers: getTokenizedHeaders(),
  //   });
  //   return response.json().catch((e) => {
  //     Bugsnag.notify(e);
  //     return {};
  //   });
  // },
  async getTopContributors(leaderType) {
    const url = new URL(
      getLambdaUrl(`/ft/top/contributors?leaderType=${leaderType}`)
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    // console.log("type", leaderType);
    const json = await response.json();
    // console.log("json", json);
    return (json.leaders || json.overallLeaders || []).map((c) => {
      return {
        image: c.profilePic,
        score: c.score || c.newScore,
        name: c.name,
        link: `https://www.tublian.com/profile/${c.userId}`,
        prevScore: c.prevScore,
        scoreChange: c.scoreChange,
      };
    });
  },

  async githubIssueStatus(userName, repoName, repoId, issueNumber) {
    const url = new URL(
      getLambdaUrl(
        `/ft/standout/hacks/github/project/status/${userName}/${repoName}/${repoId}/${issueNumber}`
      )
    );
    var response = await fetch(url, {
      method: "GET",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getStackoverflowHacks(query) {
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/sow/questions/${query}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async _getStackoverflowHacks(userId) {
    const languages = userLanguages(userId);
    // console.log(">>>> languages ", languages);
    var response = await fetch(
      getLambdaUrl(`/ft/standout/hacks/sow/questions/${languages}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getRemoteJobs(userId) {
    const languages = userLanguages(userId);
    const url = new URL(getLambdaUrl(`/ft/jobs/search/remote`));
    url.searchParams.append("filter", languages);
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => { });
  },
  async getJobs(userId, languages = userLanguages(userId)) {
    // const languages = userLanguages(userId);
    // console.log("🚀 ~ getJobs ~ languages:", languages);
    const url = new URL(getLambdaUrl(`/ft/jobs/search/yc`));
    url.searchParams.append("filter", languages);
    url.searchParams.append("userId", userId);
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getGoogleJobs(query, ip, location) {
    const url = new URL(getLambdaUrl(`/ft/jobs/search/google`));

    if (ip && ip.length > 0) {
      url.searchParams.append("ip", ip);
    }

    if (location && location.length > 0) {
      url.searchParams.append("location", location);
    }

    url.searchParams.append("query", query);

    // console.log("🚀 ~ getGoogleJobs ~ url:", url);

    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getJobDetailsById(jobId) {
    const url = new URL(getLambdaUrl(`/ft/google/job/listing`));
    if (jobId) {
      url.searchParams.append("jobId", jobId);
    }
    var response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async generateTokenForEdit(userId) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/edit/token/${userId}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async allowedToEdit(userId, editToken) {
    var response = await fetch(getLambdaUrl(`/ft/profile/edit/permission`), {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        userId: userId,
        editToken: editToken,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getFriendsFeed(userId) {
    var response = await fetch(getLambdaUrl(`/ft/connections/feed/${userId}`), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async createRepoForReadMe(userId, token, type) {
    // console.log(getLambdaUrl(`/ft/profile/streetcred/github/create/${userId}`));
    const url = new URL(
      getLambdaUrl(`/ft/profile/streetcred/github/create/${userId}`)
    );
    url.searchParams.append("type", type);
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async createCodespace(fullRepoName) {
    // console.log(getLambdaUrl(`/ft/profile/streetcred/github/create/${userId}`));
    const url = new URL(
      getLambdaUrl(`/ft/standout/hacks/github/project/codespace`)
    );
    var response = await fetch(url, {
      method: "POST",
      headers: getTokenizedHeaders(),
      body: JSON.stringify({
        fullRepoName: fullRepoName,
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async getDiscordInfo(userId) {
    const profile = await ProfileEndpoint.getProfileMetadata(userId);
    return profile.sources["discord"] ? profile.sources["discord"] : null;
  },

  async getProfileMetadata(userId) {
    var response = await fetch(getLambdaUrl(`/ft/profile/metadata/${userId}`), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async getProfile(userId) {
    var response = await fetch(getLambdaUrl(`/ft/profile/${userId}`), {
      method: "GET",
      headers: getHeaders(),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },

  async editProfileConfig(editToken) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/edit/config/${editToken}`),
      {
        method: "GET",
        headers: getHeaders(),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async saveProfileConfig(editToken, newConfig) {
    var response = await fetch(
      getLambdaUrl(`/ft/profile/save/config/${editToken}`),
      {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(newConfig),
      }
    );
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async addProfileForAccount(
    userId,
    name,
    profilePicUrl,
    bio,
    roles,
    location,
    additionalSources
  ) {
    const profile = {
      ...emptyProfileTemplate,
    };
    profile.userId = userId;
    profile.roles = roles;
    profile.name = name;
    profile.location = location;
    profile.profilePic = profilePicUrl;
    profile.shortBio = bio;
    profile.story.summary = bio;
    //add github and journal
    profile.sources["github"].userName = userId;
    profile.sources["journal"].userName = userId;
    if (additionalSources) {
      profile.sources["linkedin"] = additionalSources.linkedIn;
      profile.sources["twitter"] = additionalSources.twitter;
      profile.sources["stackoverflow"] = additionalSources.stackoverflow;
    }
    var response = await fetch(getLambdaUrl(`/ft/profile/add`), {
      method: "PUT",
      headers: getTokenizedHeaders(),
      body: JSON.stringify(profile),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
};

export default ProfileEndpoint;
