import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Box, styled } from "@mui/material";
import FT from "../../../libs/FT";
import CustomButton from "../CustomButton";
import { successSvg } from "../../UserHome/Base/SVG";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
export default function ReferFriend() {
  const [inviteLink, setInviteLink] = useState("");
  const [linkCopied, setLinkCopied] = useState(false);
  const [isLinkAvailable, setIsLinkAvailable] = useState(false);

  const user = userProfile(localStorage);

  const referFriend = async () => {
    const res = await FT.referFriend();
    const inviteUrl = res.inviteLink;
    if (inviteUrl) {
      setInviteLink(inviteUrl);
      setIsLinkAvailable(true);
    }
    // console.log("done");
  };

  const handleCopy = () => {
    setLinkCopied(true);
    logEvent("Copied referral link", user.userId());

    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  useEffect(() => {
    referFriend();
  }, []);

  return (
    <Root>
      <ReferBox>
        <ReferContent>
          {" "}
          {/* <StyledTooltip
            title="The user you referred will create an account for credits to be awarded."
            componentsProps={{
              tooltip: {
                sx: {
                  width: "184px",
                  background: "#1E1E1E",
                  color: "#FEFEFE",
                  border: "1px solid #292929",
                  borderRadius: "6px",
                  padding: "4px 8px",
                  fontWeight: 400,
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                },
              },
            }}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
            arrow={false}
            placement="bottom-start"
          > */}
            {/* <HelpOutline /> */}
          {/* </StyledTooltip> */}
          Refer a friend
        </ReferContent>
        <Box>
          {linkCopied && (
            <CopiedButton onClick={referFriend}>
              {successSvg}Link Copied!!
            </CopiedButton>
          )}
          {!linkCopied && (
            <CopyToClipboard onCopy={handleCopy} text={encodeURI(inviteLink)}>
              <CustomButton
                type="company"
                size="medium"
                style={{
                  padding: "4px 8px",
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#1e1e1e",
                }}
                disabled={!isLinkAvailable}
              >
                Copy Referral Link
              </CustomButton>
            </CopyToClipboard>
          )}
        </Box>
      </ReferBox>
    </Root>
  );
}

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 4,
  alignItems: "center",
  marginBottom: 18,
}));

// const StyledTooltip = styled(Tooltip)(({ theme }) => ({
//   color: "#888",
//   backgroundImage: "none",
//   cursor: "pointer",
//   marginRight: 4,
// }));

const ReferBox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const ReferContent = styled("p")(({ theme }) => ({
  color: "#cfcfcf",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  display: "flex",
  alignItems: "center",
}));

const CopiedButton = styled("button")(({ theme }) => ({
  color: "#b7b7b7",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  backgroundColor: "transparent",
  padding: "4px 8px",
  border: "1px solid #1e1e1e",
  borderRadius: 16,
  display: "flex",
  gap: 4,
}));
