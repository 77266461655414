import { useRef, RefObject, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import DocsContents from "./DocsContents";
import DocsLinks from "./DocsLinks";
import documentationBanner from "../../images/documentation-banner.png";

const DocsPresentation: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("");

  const sectionRefs: { [key: string]: RefObject<HTMLDivElement> } = {
    releaseNotes: useRef<HTMLDivElement>(null),
    systemRequirements: useRef<HTMLDivElement>(null),
    setupGuide: useRef<HTMLDivElement>(null),
    faqs: useRef<HTMLDivElement>(null),
  };

  const handleScrollToSection = (sectionId: string) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionId);
    }
  };

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          pb: "48px",
          [theme.breakpoints.down(768)]: {
            width: "100%",
            height: "200px",
          },
        }}
      >
        <Box
          component="img"
          src={documentationBanner}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            [theme.breakpoints.down(768)]: {
              borderRadius: "20px",
            },
          }}
          alt="tos banner"
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "40px" }}
      >
        <DocsContents sectionRefs={sectionRefs} />
        <DocsLinks
          handleScrollToSection={handleScrollToSection}
          activeSection={activeSection}
        />
      </Box>
    </>
  );
};

export default DocsPresentation;
