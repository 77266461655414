import { Link } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { IProjectsCardContent } from "../../types";
import TextUtils from "../../../../libs/TextUtils";
import DetailedCustomTooltip from "../../../Common/DetailedCustomTooltip";

const ProjectsCardContent = ({
  type,
  issueLink,
  projectRepoLink,
  repoName,
  repoDescription,
  issue,
  score,
  handleProjectRepoLink,
  openLinksExternally,
}: IProjectsCardContent) => {
  if (type === "project_view" || type === "recent_projects") {
    return (
      <CardContentWrap>
        <StyledLinkText>
          <Link
            onClick={handleProjectRepoLink}
            to={{ pathname: projectRepoLink }}
            target={openLinksExternally ? "_blank" : "_self"}
          >
            <span>{TextUtils.capsFirstLetter(repoName)}</span>
          </Link>
        </StyledLinkText>
        <RepoDescWrap>
          <RepoDesc>{TextUtils.capsFirstLetter(repoDescription)}</RepoDesc>
        </RepoDescWrap>
      </CardContentWrap>
    );
  }

  // dashboard view
  return (
    <CardContentWrap>
      <RepoWrap>
        <StyledRepo>
          Repo:{" "}
          <Link to={projectRepoLink}>
            <span>{TextUtils.capsFirstLetter(repoName)}</span>
          </Link>
        </StyledRepo>
      </RepoWrap>
      <BodyContent>
        <Link to={issueLink} style={{ display: "flex" }}>
          <IssueName>{TextUtils.capsFirstLetter(issue)}</IssueName>
        </Link>
        <FooterCols>
          <DetailedCustomTooltip
            tooltipId="project-card"
            title={
              <Box>
                <Typography component="h4">Estimated Streetcred</Typography>
                <Typography>
                  This number tells you how much streetcred you are likely to
                  gain from working on this project. Keep in mind, though, that
                  the actual amount could be different.
                </Typography>
              </Box>
            }
          >
            <StyledScore sx={{ margin: "8px 0" }}>
              Est. Score: <span>{score?.toFixed(2) || "N/A"}</span>
            </StyledScore>
          </DetailedCustomTooltip>
        </FooterCols>
      </BodyContent>
    </CardContentWrap>
  );
};

export default ProjectsCardContent;

const CardContentWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(700)]: {
    border: "1px solid #414141",
    borderRadius: 10,
    padding: "12px 0",
    marginBottom: 16,
  },
}));

const StyledLinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#888",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  "& span": {
    color: "#FEFEFE",
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  [theme.breakpoints.down(700)]: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    width: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
}));

const RepoWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: 8,
  [theme.breakpoints.down(700)]: {
    borderBottom: "1px solid #414141",
    marginBottom: 12,
    paddingBottom: 12,
  },
}));

const StyledRepo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1.25,
  color: "#888",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  "& span": {
    color: "#FEFEFE",
    marginLeft: 8,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.25,
  },
  [theme.breakpoints.down(700)]: {
    fontSize: "0.75rem",
    lineHeight: 1,
    width: "100%",
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const RepoDescWrap = styled(Box)(({ theme }) => ({
  marginTop: "4px",
  display: "flex",
  marginBottom: "12px",
  height: "44px",
  [theme.breakpoints.down(700)]: {
    marginBottom: "unset",
  },
}));

const RepoDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  color: "#CFCFCF",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  [theme.breakpoints.down(700)]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const IssueName = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: 1.5,
  color: "#FEFEFE",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  [theme.breakpoints.down(390)]: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
  },
}));

const BodyContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(700)]: {
    padding: "0 12px",
  },
}));

const StyledScore = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  color: "#888",
  marginTop: 14,
  cursor: "help",
  "& span": {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "30px",
    background:
      "linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    marginLeft: 12,
  },
  [theme.breakpoints.down(700)]: {
    marginTop: 12,
    marginBottom: "unset",
  },
}));

const FooterCols = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
