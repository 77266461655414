import { UserProvider } from "./Presentation/UserContext";
import Sidebar from "../UserHome/Base/Sidebar/Sidebar";
import MainLayout from "../UserHome/Base/MainLayout";
import DocumentationPresentation from "../TosDocs/DocsPresentation";

const TosDocumentation = () => {
  return (
    <div>
      <UserProvider>
        <Sidebar />
        <MainLayout pageHeader="TOS Documentation">
          <main>
            <div>
              <DocumentationPresentation />
            </div>
          </main>
        </MainLayout>
      </UserProvider>
    </div>
  );
};

export default TosDocumentation;
