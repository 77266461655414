import { Box, Typography, styled } from "@mui/material";

import { MessageQuestion } from "iconsax-react";
import earnMore from "../../../images/landing/OsPage/os-earn-more.png";
import buildMore from "../../../images/landing/OsPage/os-build-more.png";
import osFocus from "../../../images/landing/OsPage/os-focus.png";

export const data = [
  {
    image: earnMore,
    title: "Redefine How Software is Built",
    subTitle:
      `Tublian OS is transforming the landscape of software development. What once required a full team can now be achieved by a single developer. With AI-powered agents handling bidding, building, and project management, Tublian OS empowers you to take on large-scale projects solo, boosting both your capabilities and earnings.
       The future is in your hands—imagine running a billion-dollar software company as an individual developer. Join the revolution with Tublian.`,
    id: 1,
  },
  {
    image: buildMore,
    title: "Meet Your Team",
    subTitle:
      "You’re no longer working solo. With powerful Tublian AI agents at your side, you have the support you need. Our BizDev Agent finds promising opportunities, the Bid Agent helps you craft winning proposals, and our Developer Agents assist in building software—so you can focus on your ambition and creativity.",
    id: 2,
  },
  {
    image: osFocus,
    title: "Build at Scale",
    subTitle:
      "Harness the power of Tublian OS and AI agents to scale your projects effortlessly, taking on more opportunities and expanding your impact.",
    id: 3,
  },
];

const HowItWorksSection = () => {
  return (
    <>
      <HowTag>
        <MessageQuestion /> How
      </HowTag>
      <SectionHeader>Let Your Genius Flow</SectionHeader>
      <Subtext>For Everything Else, Trust Your Tublian Agent.</Subtext>

      <SWDevFutureSection>
        {data?.map(({ image, title, subTitle, id }) => (
          <TubliOsAdvs odd={(id % 2 !== 0).toString()} key={id}>
            <Box className="textContent">
              <Typography component="h4">{title}</Typography>
              <Typography>{subTitle}</Typography>
            </Box>
            <Box className="illustrationWrap">
              <img src={image} alt={title} />
            </Box>
          </TubliOsAdvs>
        ))}
      </SWDevFutureSection>
    </>
  );
};

export default HowItWorksSection;

const HowTag = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  gap: "8px",
  borderRadius: "20px",
  background: "linear-gradient(180deg, #222325 0%, #383A3D 100%)",
  border: "1px solid #222325",
  width: "105px",
  height: "32px",
  padding: "4px 20px",
  color: "#FEFEFE",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 700,
});

const SectionHeader = styled("h1")(({ theme }) => ({
  fontSize: "3rem",
  lineHeight: "3.828125rem",
  fontWeight: 700,
  textAlign: "center",
  color: "#FEFEFE",
  margin: "28px 0 20px",
  [theme.breakpoints.down(1025)]: {
    fontSize: "2.5rem",
    lineHeight: "3.19rem",
  },
  [theme.breakpoints.down(768)]: {
    fontSize: "1.5rem",
    lineHeight: "1.91375rem",
  },
}));

const Subtext = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "2rem",
  color: "#FEFEFE",
  maxWidth: "759px",
  textAlign: "center",
  zIndex: 3,
  [theme.breakpoints.down(900)]: {
    maxWidth: "100%",
  },
  [theme.breakpoints.down(768)]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

const SWDevFutureSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "100px",
  marginTop: "60px",
  "& .textContent": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    flex: 1,
    "& h4": {
      color: "#FEFEFE",
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: "2.87125rem",
      [theme.breakpoints.down(768)]: {
        fontSize: "1.5rem",
        lineHeight: "1.91375rem",
      },
    },
    "& p": {
      color: "#FEFEFE",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },

  [theme.breakpoints.down(1025)]: {
    padding: "0 76px",
  },
  [theme.breakpoints.down(768)]: {
    padding: 0,
    gap: "28px",
    width: "100%",
  },
}));

const TubliOsAdvs = styled(Box)<{ odd: string }>(({ theme, odd }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: odd === "true" ? "row" : "row-reverse",
  gap: "40px",
  "& .illustrationWrap": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    "& img": {
      maxWidth: "calc(100% - 16px)",
      maxHeight: "calc(100% - 48px)",
    },
    [theme.breakpoints.down(1200)]: {
      width: "627px",
    },
    [theme.breakpoints.down(1025)]: {
      width: "100%",
    },
    [theme.breakpoints.down(768)]: {
      height: "432px",
    },
  },
  [theme.breakpoints.down(1025)]: {
    flexDirection: "column",
  },
}));
