export function userProfile(localStorage) {
  return (function () {
    function getData(key) {
      return localStorage.getItem(key);
    }

    function setData(key, value) {
      localStorage.setItem(key, value);
    }

    function removeData(key) {
      localStorage.removeItem(key);
    }

    const token = function () {
      return getData("userData.token");
    };

    const setToken = function (token) {
      setData("userData.token", token);
    };

    const providerId = function () {
      return getData("userData.providerId");
    };

    const setProviderId = function (id) {
      setData("userData.providerId", id);
    };

    const provider = function () {
      return getData("userData.provider");
    };

    const createdDate = function () {
      return getData("userData.createdDate");
    };

    const setProvider = function (id) {
      setData("userData.provider", id);
    };

    const userId = function () {
      return getData("userData.userId");
    };

    const setUserId = function (id) {
      setData("userData.userId", id);
    };

    const userAvatar = function () {
      return getData("userData.userAvatar");
    };

    const setUserAvatar = function (url) {
      setData("userData.userAvatar", url);
    };

    const roles = function () {
      return getData("userData.roles");
    };

    const setRoles = function (roles) {
      setData("userData.roles", roles);
    };

    const hasDiscord = function () {
      return discord() !== null;
    };

    const discord = function () {
      return getData("userData.discord");
    };

    const setDiscord = function (discord) {
      setData("userData.discord", discord);
    };

    const githubScopes = function () {
      return getData("userData.githubScopes");
    };

    const setGithubScopes = function (githubScopes) {
      setData("userData.githubScopes", githubScopes);
    };

    const profilePrompt = function () {
      return getData("userData.profilePrompt");
    };

    const setProfilePrompt = function (profilePrompt) {
      setData("userData.profilePrompt", profilePrompt);
    };

    const languages = function () {
      return getData("userData.languages");
    };

    const setLanguages = function (languages) {
      setData("userData.languages", languages);
      setData("userData.selectedLanguages", languages);
    };

    const setShowWelcomeMessage = function (showWelcomeMessage) {
      setData("userData.showWelcomeMessage", showWelcomeMessage);
    };

    const showWelcomeMessage = function () {
      return getData("userData.showWelcomeMessage") === "true";
    };

    const setCreatedDate = function (datetime) {
      setData("userData.createdDate", datetime);
    };

    const selectOrUnselectLanguage = function (lang) {
      const selection = selectedLanguages();
      if (selection.includes(lang)) {
        const newSelection = selection.filter((l) => l !== lang);
        if (newSelection.length > 0) {
          setData("userData.selectedLanguages", newSelection.join(","));
        }
      } else {
        const newSelection = selection.concat([lang]);
        setData("userData.selectedLanguages", newSelection.join(","));
      }
    };

    const defaultLanguages = function () {
      return languages()?.split(",");
    };

    const setSelectedLanguages = function (languages) {
      setData("userData.selectedLanguages", languages);
    };

    const selectedLanguages = function () {
      const selectedLangs = getData("userData.selectedLanguages");
      if (selectedLangs) {
        return selectedLangs.split(",");
      }
      return defaultLanguages();
    };

    const isShowMessage = function (msgId) {
      const dismissedMsgId = getDismissMessageId();
      return !dismissedMsgId || !dismissedMsgId.includes(msgId)
    };

    const getDismissMessageId = function () {
      return getData("userData.dismissMessageId");
    };

    const setDismissMessageId = function (msgId) {
      return setData("userData.dismissMessageId", msgId);
    };

    const getFeaturesCardsId = function () {
      const data = JSON.parse(getData("userData.featuresCards"));
      return data || [];
    };

    const setFeaturesCardsId = function (featuresCardsId) {
      setData("userData.featuresCards", featuresCardsId);
    };

    const openToInternship = function () {
      return getData("userData.openToInternship") === "true";
    };

    const setOpenToInternship = function (value) {
      setData("userData.openToInternship", value); // Store in localStorage
    };

    const cohort = function () {
      return getData("userData.cohort");
    };

    const setCohort = function (value) {
      setData("userData.cohort", value); // Store in localStorage
    };

    const betaMode = function () {
      return getData("userData.betaMode") === "true";
    };

    const setBetaMode = function (value) {
      setData("userData.betaMode", value); // Store in localStorage
    };

    const reference = function () {
      return getData("userData.reference");
    };

    const setReference = function (reference) {
      return setData("userData.reference", reference);
    };

    const clear = function () {
      removeData("userData.userAvatar");
      removeData("userData.userId");
      removeData("userData.provider");
      removeData("userData.providerId");
      removeData("userData.token");
      removeData("userData.roles");
      removeData("userData.profilePrompt");
      removeData("userData.languages");
      removeData("userData.selectedLanguages");
      removeData("userData.openToInternship");
      removeData("userData.discord");
      removeData("userData.reference");
    };

    return {
      token: token,
      setToken: setToken,
      provider,
      setProvider,
      providerId,
      setProviderId,
      userId: userId,
      showWelcomeMessage: showWelcomeMessage,
      getFeaturesCardsId: getFeaturesCardsId,
      setFeaturesCardsId: setFeaturesCardsId,
      createdDate: createdDate,
      setUserId: setUserId,
      userAvatar: userAvatar,
      setUserAvatar: setUserAvatar,
      setShowWelcomeMessage: setShowWelcomeMessage,
      roles: roles,
      setRoles: setRoles,
      hasDiscord: hasDiscord,
      discord: discord,
      setDiscord: setDiscord,
      githubScopes: githubScopes,
      setGithubScopes: setGithubScopes,
      profilePrompt: profilePrompt,
      setProfilePrompt: setProfilePrompt,
      languages: languages,
      setLanguages: setLanguages,
      selectOrUnselectLanguage: selectOrUnselectLanguage,
      selectedLanguages: selectedLanguages,
      setSelectedLanguages: setSelectedLanguages,
      getDismissMessageId: getDismissMessageId,
      setDismissMessageId: setDismissMessageId,
      isShowMessage: isShowMessage,
      setCreatedDate: setCreatedDate,
      defaultLanguages: defaultLanguages,
      openToInternship: openToInternship,
      setOpenToInternship: setOpenToInternship,
      cohort: cohort,
      setCohort: setCohort,
      betaMode: betaMode,
      setBetaMode: setBetaMode,
      reference: reference,
      setReference: setReference,
      clear,
    };
  })();
}
