import CopilotEndpoint from "./CopilotEndpoint";
import ProfileEndpoint from "./ProfileEndpoint";
import { userProfile } from "./UserProfile";
import { logEvent } from "./amplitude";
import { saveUserLearningEvent } from "../components/Common/Analytics/UserEventsAPI";

const LearningRoadmapEndpoint = {
  async createRoadmap(learningObjective, queryType) {
    saveUserLearningEvent(learningObjective);
    const userId = userProfile(localStorage).userId();
    const prompt = userProfile(localStorage).profilePrompt();
    logEvent("8020", userId, {
      action: "createRoadmap",
      learningObjective: learningObjective,
    });
    console.log(">>>> creating learning roadmap");
    const response = await CopilotEndpoint.buildLearningRoadmap(
      learningObjective,
      prompt,
      queryType,
      userId
    );
    return response;
  },

  async createDeepdiveRoadmap({
    learningObjectives,
    learningPrompt,
    roadmapId,
    phaseId,
    sectionNumber,
  }) {
    // saveUserLearningEvent(learningObjectives);
    const userId = userProfile(localStorage).userId();

    logEvent("8020", userId, {
      action: "createDeepdiveRoadmap",
      learningObjectives,
    });
    console.log(">>>> creating deep-dive roadmap");

    const response = await CopilotEndpoint.buildDeepdiveRoadmap(
      learningObjectives,
      learningPrompt,
      roadmapId,
      phaseId,
      sectionNumber
    );
    return response;
  },
  async listRoadmaps() {
    const response = await ProfileEndpoint.listLearningRoadmaps();
    // console.log("🚀 ~ listRoadmaps ~ response:", response)
    return response.roadmaps;
  },
  async getRoadmap(roadmapId, userId) {
    const response = await ProfileEndpoint.getLearningRoadmap(
      roadmapId,
      userId
    );
    return response;
  },
  // async getCredits() {
  //   const response = await FT.getLearningCredits();
  //   return response.credits;
  // },
  // async updateCredits(newCredits) {
  //   const response = await FT.updateLearningCredits(newCredits);
  //   return response;
  // },
};

export default LearningRoadmapEndpoint;
