import { useEffect } from "react";
import { Box, Button, styled } from "@mui/material";
import trialImage from "../../../../images/trial-form-image.png";
import Spinner from "../../../Common/Spinner";
import { CancelBtn } from "../../../UserHome/Base/SVG";
import TublianOsFormInput from "./TublianOsFormInput";
import { useTublianOsContext } from "../TublianOsContext";

const TublianOsWaitlistForm = () => {
  const {
    waitlistDetails,
    handleCloseModal,
    loading,
    handleSubmit,
    getHintMessage,
    handleFieldChange,
  } = useTublianOsContext();

  useEffect(() => {
    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormWrapper>
        <SvgBox onClick={handleCloseModal}>{CancelBtn}</SvgBox>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "28.36px",
          }}
        >
          <img src={trialImage} alt="Trial Form" />
        </Box>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TublianOsFormInput
            label="Name"
            placeholder="Full Name"
            value={waitlistDetails.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            hint={getHintMessage("name")}
          />

          <TublianOsFormInput
            label="Email"
            placeholder="Email address"
            value={waitlistDetails.email}
            onChange={(e) => handleFieldChange("email", e.target.value)}
            hint={getHintMessage("email")}
          />

          <JoinWaitlistBtn
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? <Spinner secColor="white" size={20} /> : "Join Waitlist"}
          </JoinWaitlistBtn>
        </form>
      </FormWrapper>
    </>
  );
};

export default TublianOsWaitlistForm;

const SvgBox = styled(Box)({
  position: "absolute",
  right: 10,
  top: 10,
  cursor: "pointer",
  "& svg": {
    color: "#121212",
    width: 32,
    height: 32,
  },
});

const FormWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#19191B",
  borderRadius: "10px",
  width: "416px",
  // height: '516px',
  padding: "20px",
  height: "fit-content",
  position: "relative",
}));

const JoinWaitlistBtn = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  color: "#FEFEFE",
  background: "linear-gradient(rgba(253, 214, 73, 1), rgba(151, 128, 44, 1))",
  border: "1px solid rgba(253, 214, 73, 1)",
  borderRadius: "20px",
  padding: "8px 76px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "12px",
  "&:hover": {
    background: "#97802C",
  },
  [theme.breakpoints.down(900)]: {
    width: "100%",
    maxWidth: "358px",
  },
}));
