import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  styled,
} from "@mui/material";
import { TublianOsInputProps } from "../TublianOsResources";

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#292929",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#292929",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#292929",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#E0E1E6",
    lineHeight: "17.86px",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .MuiInputBase-input": {
    color: "#fefefe",
    height: "50px",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& input:-webkit-autofill": {
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "#fefefe",
    transition: "background-color 5000s ease-in-out 0s",
    boxShadow: "none",
  },
}));

const ErrorText = styled("div")({
  color: "red",
  fontSize: "12px",
  marginTop: "5px",
});

const TublianOsFormInput: React.FC<TublianOsInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  hint,
}) => (
  <Box sx={{ marginBottom: "16px" }}>
    <FormHelperText
      sx={{
        color: "#FEFEFE",
        fontWeight: 500,
        marginBottom: "6px",
        fontSize: "16px",
        lineHeight: "20.42px",
      }}
    >
      {label} <span style={{ color: "red" }}>*</span>
    </FormHelperText>
    <FormControl fullWidth={true}>
      <CustomOutlinedInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FormControl>
    {hint && <ErrorText>{hint}</ErrorText>}
  </Box>
);

export default TublianOsFormInput;
