/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  dashboardSvg,
  projectsSvg,
  questionsSvg,
  challengeSvg,
  exploreSvg,
  // profileSvg,
  // logoutSvg,
  // discordSvg,
  playSvg,
  tutorialSvg,
  troubleshootSvg,
  learnSvg,
  notificationSvg,
  aiInternshipSvg,
} from "../SVG";
import { userProfile } from "../../../../libs/UserProfile";
import { useAppContext } from "../../../../libs/contextLib";
import FT from "../../../../libs/FT";
import { Box, useMediaQuery } from "@mui/material";
import { useStyles } from "./SidebarStyles";
import tublianLogo from "../../../../images/tublianLogo.svg";
import UserAvatarMenu from "../../../Feed/Menus/UserAvatarMenu";
import mobileLogo from "../../../../images/new-logo.svg";
import hamburger from "../../../../images/landing/menu.svg";
import close from "../../../../images/landing/Close.svg";
import { ConnectionRequest } from "../../../Notifications/notificationTypes";
import TosDocBanner from "./TosDocBanner";
import tabMenu from "../../../../images/docs-tab-menu.png";
import { useTheme } from "@mui/system";

export default function Sidebar({ isPublic = false }: { isPublic?: boolean }) {
  const navRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [activePage, setActivePage] = useState("");
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 769px)");
  // const { userHasAuthenticated, setUser } = useAppContext() as any;
  const [hasAccessToInternship, setHasAccessToInternship] = useState<
    boolean | null
  >(null);
  const [hasAccessToTos, setHasAccessToTos] = useState<boolean | null>(null);
  const { fetchNotifications, pairRequestCount, connectionCount } =
    useAppContext() as unknown as {
      fetchNotifications: () => void;
      pairRequestCount: Notification[];
      connectionCount: ConnectionRequest[];
    };

  const showNotificationAlert =
    pairRequestCount?.length > 0 || connectionCount?.length > 0;

  // const userSignOut = () => {
  //   userCache.clear();
  //   userHasAuthenticated(false);
  //   history.push("/welcome");
  // };

  // function signOut() {
  //   if (userCache.provider() !== "dummy") {
  //     userSignOut();
  //   } else {
  //     (async () => {
  //       try {
  //         const adminResponse = await FT.unimpersonate();
  //         adminSignOut(adminResponse);
  //       } catch {
  //         userSignOut();
  //       }
  //     })();
  //   }
  // }

  // const adminSignOut = async (adminResponse: any) => {
  //   userCache.setToken(adminResponse.jwt);
  //   userCache.setProvider(adminResponse.provider);
  //   userCache.setProviderId(adminResponse.providerId);
  //   userCache.setUserId(adminResponse.userId);
  //   setUser(await FT.getAuthorizedUser());
  //   history.push("/admin");
  // };

  const showNavbar = () => {
    if (navRef.current) {
      navRef.current.classList.toggle(classes.responsiveNav);
    }
  };

  const theme = useTheme();
  const user = userProfile(localStorage);

  const checkAccessToInternship = async () => {
    try {
      const response = await FT.get(user.userId(), {});
      if (
        response &&
        response.hasAccessToInternship &&
        response.cohort === "os-internship-v3"
      ) {
        setHasAccessToInternship(response.hasAccessToInternship);
      }
    } catch (error) {
      // console.error("Error fetching user data:", error);
      setHasAccessToInternship(false);
    }
  };

  const checkAccessToTos = async () => {
    try {
      const response = await FT.get(user.userId(), {});
      if (response && response.hasAccessToTos && response.tosApiKey) {
        setHasAccessToTos(response.hasAccessToTos);
      }
    } catch (error) {
      setHasAccessToTos(false);
    }
  };

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/") {
      setActivePage("home");
    } else {
      const parts = pathname.split("/").filter((part) => part !== "");
      if (parts[0] === "project") {
        setActivePage("projects");
      } else {
        setActivePage(parts[0]);
      }
    }
  }, [location]);

  // eslint-disable-next-line
  const [dropDownActive, setDropDownActive] = useState({
    community: false,
    help: false,
  });

  // const logDiscordEvent = () => {
  //   logEvent("Discord", userCache.userId());
  //   return true;
  // };

  useEffect(() => {
    fetchNotifications();
    checkAccessToInternship();
    checkAccessToTos();
  }, []); // eslint-disable-line

  return (
    <div id="sidebar" className={classes.sidebar}>
      <div className={`${classes.logoWrapper} `}>
        {isMobile && (
          <div className={`${classes.logo} `}>
            <img
              src={mobileLogo}
              alt="Tublian logo"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        )}
      </div>
      <div ref={navRef} className={classes.sidebarInner}>
        <div>
          <img
            alt=""
            src={close}
            className={`${classes.menu} ${classes.closeNav}`}
            onClick={showNavbar}
          />
        </div>
        <div className={`${classes.logoWrapper} ${classes.logoWrapper2} `}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={tublianLogo}
              alt="Tublian logo"
              style={{ height: "100%", width: "100%" }}
              className={`${classes.hideOnTab}`}
            />
          </div>
          <div>
            <UserAvatarMenu />
          </div>
        </div>
        <div className={`${classes.navLinkWrap}`}>
          <NavLink
            to="/home"
            activeClassName={classes.sidebar_link_active}
            className={`${classes.sidebarLink}`}
          >
            {dashboardSvg}
            <span className={classes.sidebar_link_text}>Dashboard</span>
          </NavLink>
        </div>
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to={`/notifications`}
            activeClassName={classes.sidebar_link_active}
            className={classes.sidebarLink}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {notificationSvg}
              {showNotificationAlert && (
                <div className={classes.notificationDot}></div>
              )}
            </div>
            <span className={classes.sidebar_link_text}>Notifications</span>
          </NavLink>
        </div>
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/playground"
            activeClassName={classes.sidebar_link_active}
            className={`${classes.sidebarLink}`}
          >
            {playSvg}
            <span className={classes.sidebar_link_text}>
              {isMobile ? "Copilot" : "Jobs Copilot"}
            </span>
          </NavLink>
        </div>

        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/learn"
            activeClassName={classes.sidebar_link_active}
            className={`${classes.sidebarLink} sidebar_links`}
          >
            {learnSvg}
            <span className={classes.sidebar_link_text}>8020</span>
          </NavLink>
        </div>
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/projects"
            activeClassName={classes.sidebar_link_active}
            className={
              activePage === "projects"
                ? `${classes.sidebar_link_active} ${classes.sidebarLink}`
                : `${classes.sidebarLink}`
            }
          >
            {projectsSvg}
            <span className={classes.sidebar_link_text}>Open Source</span>
          </NavLink>
        </div>
        {hasAccessToInternship && (
          <div className={`${classes.navLinkWrap} `}>
            <NavLink
              to="/ai-powered-os-internship"
              activeClassName={classes.sidebar_link_active}
              className={classes.sidebarLink}
            >
              {aiInternshipSvg}
              <span className={classes.sidebar_link_text}>OS Internship</span>
            </NavLink>
          </div>
        )}
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/questions"
            activeClassName={classes.sidebar_link_active}
            className={`${classes.sidebarLink}`}
          >
            {questionsSvg}
            <span className={classes.sidebar_link_text}>Questions</span>
          </NavLink>
        </div>
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/challenge"
            activeClassName={classes.sidebar_link_active}
            className={`${classes.sidebarLink} sidebar_links`}
          >
            {challengeSvg}
            <span className={classes.sidebar_link_text}>Challenges</span>
          </NavLink>
        </div>
        <div className={`${classes.navLinkWrap} `}>
          <NavLink
            to="/feed"
            activeClassName={classes.sidebar_link_active}
            className={classes.sidebarLink}
          >
            {exploreSvg}
            <span className={classes.sidebar_link_text}>Explore</span>
          </NavLink>
        </div>
        {/* <div className={`${classes.navLinkWrap} `}>
          <a
            href="https://discord.gg/S6KGq3NR7F"
            onClick={() => logDiscordEvent()}
            id=""
            className={`
              ${classes.sidebarLink}
              ${dropDownActive.community ? classes.sidebar_link_active : ""}
              sidebar_links
            `}
            target="_blank"
            rel="noreferrer"
          >
            {discordSvg}
            <span className={classes.sidebar_link_text}>Discord</span>
          </a>
        </div> */}

        {/* {!isPublic && (
          <div className={`${classes.navLinkWrap} `}>
            <NavLink
              to={`/profile/${userCache.userId()}`}
              activeClassName={classes.sidebar_link_active}
              className={classes.sidebarLink}
            >
              {profileSvg}
              <span className={classes.sidebar_link_text}>Profile</span>
            </NavLink>
          </div>
        )} */}

        {dropDownActive.help && (
          <>
            <div className={classes.navLinkWrap}>
              <a href="#" className={`${classes.sidebarLink} sidebar_links`}>
                {tutorialSvg}
                <span className={classes.sidebar_link_text}>Tutorials</span>
              </a>
            </div>

            <div className={classes.navLinkWrap}>
              <a href="#" className={`${classes.sidebarLink} sidebar_links`}>
                {troubleshootSvg}
                <span className={classes.sidebar_link_text}>Troubleshoot</span>
              </a>
            </div>
          </>
        )}
        {/* {!isPublic && (
          <div className={classes.navLinkWrap}>
            <a
              href="#"
              onClick={signOut}
              className={`${classes.sidebarLink} ${classes.hideOnMobile} sidebar_links`}
              target="_blank"
              rel="noreferrer"
            >
              {logoutSvg}
              <span className={classes.sidebar_link_text}>Logout</span>
            </a>
          </div>
        )} */}
        {hasAccessToTos && (
          <Box
            sx={{
              [theme.breakpoints.down(960)]: { display: "none" },
              [theme.breakpoints.down(769)]: {
                display: "block",
                width: "202px",
              },
            }}
          >
            <TosDocBanner />
          </Box>
        )}

        <NavLink
          to="tos-docs"
          className={`${classes.hideTosMenu} sidebar_links`}
        >
          <img src={tabMenu} style={{ width: "100%" }} alt="tos menu" />
        </NavLink>
      </div>
      <div>
        <img
          alt=""
          src={hamburger}
          className={classes.hamMenu}
          onClick={showNavbar}
        />
      </div>
    </div>
  );
}
