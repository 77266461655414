import { FC, createContext, useContext, useState } from "react";
import FT from "../../../../libs/FT";
import {
  PopupDataType,
  TublianOsContextType,
  WaitlistDetails,
} from "../TublianOsResources";

const TublianOsContext = createContext<TublianOsContextType | undefined>(
  undefined
);

export function useTublianOsContext() {
  const context = useContext(TublianOsContext);
  if (!context) {
    throw new Error(
      "useTublianOsContext must be used within a TublianOsProvider"
    );
  }
  return context;
}

export const defaultTosWaitlistDetails = {
  isActive: false,
  name: "",
  email: "",
};

export const TublianOsProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [waitlistDetails, setWaitlistDetails] = useState<WaitlistDetails>(
    defaultTosWaitlistDetails
  );
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
    name: "",
    email: "",
  });
  const [tosStatusPopup, setTosStatusPopup] = useState<PopupDataType>({
    isActive: false,
    msg: "",
    subMsg: "",
    status: null,
    mode: null,
  });

  const handleOpenWaitlistForm = () => {
    setWaitlistDetails((prevData) => ({
      ...prevData,
      isActive: true,
    }));
  };

  const handleCloseModal = () => {
    setWaitlistDetails(defaultTosWaitlistDetails);
    setFormErrors({ name: "", email: "" });
  };

  const validateField = (field: string, value: string) => {
    if (!value.trim()) {
      return `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
    }
    switch (field) {
      case "name":
        return value.trim().length > 1
          ? ""
          : "Name must be at least 2 characters.";
      case "email":
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value) ? "" : "Invalid email address.";
      default:
        return "";
    }
  };

  const handleFieldChange = (field: string, value: string) => {
    setWaitlistDetails((prev) => ({ ...prev, [field]: value }));
    setFormErrors((prev) => ({
      ...prev,
      [field]: validateField(field, value),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const nameError = validateField("name", waitlistDetails.name);
    const emailError = validateField("email", waitlistDetails.email);

    if (nameError || emailError) {
      setFormErrors({ name: nameError, email: emailError });
      return;
    }

    // Proceed with submission
    setLoading(true);
    try {
      const { name, email } = waitlistDetails;
      const waitlistResponse = await FT.addToWaitlist(name, email);
      console.log(waitlistResponse, "res");
      if (waitlistResponse.error) {
        throw new Error(waitlistResponse.error);
      }
      setTosStatusPopup({
        isActive: true,
        msg: "Your are in!",
        subMsg: "Thank you for joining the waitlist. We’ll notify you as soon as Tublian OS is ready for you to explore.",
        status: "success",
        mode: "waitlist",
      });
    } catch (error) {
      console.log(error, "error");
      setTosStatusPopup({
        isActive: true,
        msg: "Oops somthing went wrong",
        subMsg: error instanceof Error ? error.message : String(error),
        status: "error",
        mode: "waitlist",
      });
    } finally {
      setLoading(false);
      setWaitlistDetails(defaultTosWaitlistDetails);
    }
  };

  const getHintMessage = (field: string) => {
    return formErrors[field];
  };

  const contextValue: TublianOsContextType = {
    waitlistDetails,
    tosStatusPopup,
    loading,
    setLoading,
    setTosStatusPopup,
    setWaitlistDetails,
    handleCloseModal,
    handleSubmit,
    getHintMessage,
    handleOpenWaitlistForm,
    handleFieldChange,
  };

  return (
    <TublianOsContext.Provider value={contextValue}>
      {children}
    </TublianOsContext.Provider>
  );
};
