import { useParams } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "./Presentation/UserContext";
import { LearnProvider } from "../Learning/Course/LearnContext";
import LearningPresentation from "../Learning/LearningPresentation";
import CoursePresentation from "../Learning/Course/CoursePresentation";
import { ProjectsProvider } from "../Projects/ProjectsContext";
import Sidebar from "../UserHome/Base/Sidebar/Sidebar";
import MainLayout from "../UserHome/Base/MainLayout";
import RoadmapView from "../Learning/RoadmapView";
import ShareRoadmapView from "../Learning/ShareRoadmapView";
import BuyCredits from "../Learning/Course/BuyCredits";
import EightyTwentyPaymentHistory from "../Learning/Course/BuyCredits/EightyTwentyPaymentHistory";
import { LearningView } from "../Learning/Common/LearningTypes";
import { userProfile } from "../../libs/UserProfile";

export default function PersonalizedLearning({ type, view }: Props) {
  const { userName } = useParams<{ userName: string }>();
  const userCache = userProfile(localStorage);

  if (userName && userName !== userCache.userId()) {
    type = "shareRoadmapView";
  }

  const isPublic = (view && view === "public") || false;

  return (
    <Box position="relative">
      <ToastContainer />
      <ProjectsProvider>
        <LearnProvider>
          <UserProvider>
            <Sidebar isPublic={isPublic} />
            <MainLayout pageHeader="8020: Fastest Way to Learn Anything" isPublic={isPublic}>
              <LearnCardWrapper>
                {type === "roadmapView" && (
                  <RoadmapView type={type} isPublic={isPublic} />
                )}
                {type === "shareRoadmapView" && (
                  <ShareRoadmapView
                    roadmapOwner={userName}
                    isPublic={isPublic}
                  />
                )}

                {type === "roadmapListView" && (
                  <LearningPresentation isPublic={isPublic} />
                )}
                {type === "eightyTwentyPaymentHistory" && (
                  <EightyTwentyPaymentHistory isPublic={isPublic} />
                )}
                {type === "phaseView" && <CoursePresentation />}
                {type === "buyCredits" && <BuyCredits />}
              </LearnCardWrapper>
            </MainLayout>
          </UserProvider>
        </LearnProvider>
      </ProjectsProvider>
    </Box>
  );
}

type Props = {
  view: string;
  type: LearningView;
};

const LearnCardWrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#121212",
}));
