import {
  FC,
  Dispatch,
  SetStateAction,
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  useRef,
} from "react";
import { styled, Box, TextareaAutosize, Icon } from "@mui/material";
import { useLearnContext } from "./Course/LearnContext";
import Spinner from "../Common/Spinner";
import { sendSvg } from "../UserHome/Base/SVG";

const RoadmapSearch: FC<SearchInputProps> = ({
  onSearch,
  query,
  setQuery,
  errorHandler,
  disabled = false,
  placeholder = "Type in course roadmap of your choice",
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { loadingState } = useLearnContext();

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const query = event.target.value;
    setQuery(query);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Escape") {
      inputRef.current?.blur();
    }

    if (event.key === "Enter" && onSearch) {
      if (query?.length > 2) {
        onSearch(query);
      } else {
        if (errorHandler) {
          errorHandler("Search query should be more than two characters long!");
        }
      }
    }
  };

  const handleClickSearch = () => {
    if (onSearch) {
      if (query?.length > 2) {
        onSearch(query);
      } else {
        if (errorHandler) {
          errorHandler("Search query should be more than two characters long!");
        }
      }
    }
  };

  useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    }
  }, [query]);

  return (
    <StyledWrap onClick={() => inputRef.current?.focus()}>
      <StyledTextArea
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        maxLength={1024}
        disabled={disabled}
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />

      <SearchFooter>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {/* <CreditStatus hasBorder={false} /> */}
          {/* <CustomButton
            type="grey"
            size="small"
            // onClick={() => history.push("/8020/credits")}
          >
            Buy Credits
          </CustomButton> */}
        </Box>
        <Icon
          onClick={handleClickSearch}
          sx={{ color: "#888", cursor: "pointer" }}
        >
          {loadingState.creatingRoadmaps ? (
            <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
          ) : (
            sendSvg
          )}
        </Icon>
      </SearchFooter>
    </StyledWrap>
  );
};

export default RoadmapSearch;

type SearchInputProps = {
  onSearch?: (query: string) => void;
  errorHandler?: (errorMsg: string) => void;
  disabled?: boolean;
  placeholder?: string;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

const StyledWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: "#1E1E1E",
  borderRadius: "10px",
  border: "1px solid #292929",
  minHeight: "148px",
  gap: "20px",
});

const SearchFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "12px",
  justifyContent: "space-between",
});

const StyledTextArea = styled(TextareaAutosize)({
  width: "100%",
  minHeight: "68px",
  maxHeight: "360px",
  background: "transparent",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: "#FEFEFE",
  ":focus::placeholder": {
    // Hide placeholder once active
    color: "transparent",
  },
  "::placeholder": {
    color: "#888",
  },
  padding: "8px 12px",
  resize: "none",
});
