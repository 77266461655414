import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import success from "../../../../images/onboarding/success-circle.png";
import errorImg from "../../../../images/onboarding/error-circle.png";
import { CancelBtn } from "../../../UserHome/Base/SVG";
import {
  defaultTosWaitlistDetails,
  useTublianOsContext,
} from "../TublianOsContext";
import { TublianOsWaitlistProps } from "../TublianOsResources";

const TublianOsWaitlistStatus: FC<TublianOsWaitlistProps> = ({
  status = "success",
  setPopupData,
  popupData,
}) => {
  const { setWaitlistDetails } = useTublianOsContext();

  const handleCancel = () => {
    setWaitlistDetails(defaultTosWaitlistDetails);
    setPopupData((prev) => ({
      ...prev,
      isActive: false,
      msg: "",
      status: null,
      mode: null,
    }));
  };

  return (
    <StatusWrap>
      <SvgBox onClick={handleCancel}>{CancelBtn}</SvgBox>
      <Status>
        <img src={status === "success" ? success : errorImg} alt="status3" />
      </Status>
      <Box className="main-content">
        <HeaderText>{popupData.msg}</HeaderText>
        <SubHeader>{popupData.subMsg}</SubHeader>
      </Box>
    </StatusWrap>
  );
};

export default TublianOsWaitlistStatus;

const StatusWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  width: "416px",
  paddingBottom: "20px",
  borderRadius: "20px",
  background: "#19191B",
  "& .main-content": {
    padding: "0 20px",
    maxWidth: "358px",
  },
  [theme.breakpoints.down(1024)]: {
    width: "548px",
  },
  [theme.breakpoints.down(700)]: {
    width: "358px",
    // height: "395px",
  },
  [theme.breakpoints.down(390)]: {
    width: "calc(100vw - 16px)",
  },
}));

const SvgBox = styled(Box)({
  position: "absolute",
  right: 20,
  top: 20,
  cursor: "pointer",
  zIndex: 2,
  "& svg": {
    color: "#121212",
    width: 24,
    height: 24,
  },
});

const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "32px",
  color: "#fefefe",
  textAlign: "center",
  margin: "20px 0 0",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#fefefe",
  textAlign: "center",
  margin: "8px 0 20px",
  // maxWidth: "406px",
  [theme.breakpoints.down(768)]: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
}));

const Status = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "240px",
  overflow: "hidden",
  "& img": {
    position: "absolute",
    left: "calc(50% - 68px)",
    top: "52.06px",
    width: "137.5px",
    height: "137.5px",
  },
  [theme.breakpoints.down(768)]: {
    height: "187px",
    "& img": {
      left: "calc(50% - 44.865px)",
      top: "48.92px",
      width: "89.73px",
      height: "89.73px",
    },
  },
}));
