import { FC, useState } from "react";
import { Box, Icon, styled, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
import { useLearnContext } from "./LearnContext";
import {
  ActiveSectionContentType,
  ContentItem,
  ILearningProgress,
} from "../Common/LearningTypes";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import DetailedCustomTooltip from "../../Common/DetailedCustomTooltip";
import Spinner from "../../Common/Spinner";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import gallery from "../../../images/learning/gallery.png";
import { RefreshSvg, dislikeSvg } from "../../UserHome/Base/SVG";

const CourseLearningCard: FC<LearningCardProps> = ({
  id,
  thumbnail,
  title,
  link,
  description,
  resourceType = "link",
  status,
  bonusContent,
  cardIndex,
  roadmapId,
}) => {
  const userCache = userProfile(localStorage);
  const {
    learningProgress,
    activeSectionId,
    activeCourse,
    setLearningProgress,
  } = useLearnContext();
  const { setPopupStateData, setError } = useLearnContext();
  const [reacted, setReacted] = useState(false);
  const [regenerating, setRegenerating] = useState(false);

  const handleToggleContentStatus = async () => {
    const newStatus = !status;
    const resources = learningProgress?.progress || {};

    let section: ActiveSectionContentType = (
      resources as Record<string | number, ActiveSectionContentType>
    )[activeSectionId];

    if (!section || !("content" in section)) return;

    const updatedSection: ActiveSectionContentType = {
      ...section,
      content: section.content.map((contentItem) =>
        contentItem.id === id
          ? { ...contentItem, status: newStatus }
          : contentItem
      ),
    };

    const updatedLearningProgress: ILearningProgress = {
      ...learningProgress!,
      progress: {
        ...resources,
        [activeSectionId]: updatedSection,
      },
    };

    setLearningProgress(updatedLearningProgress);

    try {
      const response = await ProfileEndpoint.updatePathwayProgress(
        activeCourse.id,
        "in progress",
        updatedLearningProgress.progress,
        "course",
        roadmapId
      );

      if (!response || response.result !== "ok")
        throw new Error("Update failed");

      setPopupStateData((prev) => ({
        ...prev,
        toastData: {
          duration: 4000, // in miliseconds
          title: `Marked ${newStatus ? "complete" : "incomplete"}`,
          subtitle: `Your learning content has been marked ${
            newStatus ? "complete" : "incomplete"
          }`,
          actionMessage: "",
          action: undefined,
        },
        popupMode: "toast",
      }));
    } catch (error) {
      setError({
        error: true,
        errorMsg: `Error encountered while marking ${
          newStatus ? "complete" : "incomplete"
        }`,
      });
      setLearningProgress(learningProgress); // Revert
    } finally {
    }

    logEvent("LearnAndBuild", userCache.userId(), {
      sourceComponent: `LearningCard`,
      status: newStatus,
    });
  };

  function getVideoType(url: string) {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      return "YouTube";
    } else if (url.includes("vimeo.com")) {
      return "Vimeo";
    } else {
      return "Unknown";
    }
  }

  const handleDislike = () => {
    if (!reacted) {
      setReacted(true);
      setPopupStateData({
        isActive: true,
        course: activeCourse,
        activeContent: {
          id,
          thumbnail: thumbnail || "",
          title,
          link,
          snippet: description,
          resourceType,
          status,
          date: "",
          more_details: [],
        },
        feedbackData: {
          reaction: [],
          feedbackMsg: "",
        },
        popupMode: "feedback",
      });
    }
  };

  const handleRegenerate = async () => {
    if (!learningProgress) return;
    setRegenerating(true);

    // Create a copy of the learningProgress object
    const learningProgressCopy = { ...learningProgress };

    // Get content and bonusContent arrays from the active section
    const activeSection = learningProgressCopy.progress[activeSectionId];
    const { content, bonusContent } = activeSection;

    // Determine the bonusContent index based on the card index
    const bonusIndex = cardIndex < 2 ? 0 : 2;

    // Swap content with corresponding bonus content
    const temp = content[cardIndex];
    content[cardIndex] = bonusContent[bonusIndex];

    // Put the prev content at the back of the next bonus content
    bonusContent[bonusIndex] = bonusContent[bonusIndex + 1];
    bonusContent[bonusIndex + 1] = temp;

    // Update the learningProgress state with the modified object
    const updatedLearningProgress = {
      ...learningProgressCopy,
      progress: {
        ...learningProgressCopy.progress,
        [activeSectionId]: {
          ...activeSection,
          content,
          bonusContent,
        },
      },
    };
    setLearningProgress(updatedLearningProgress);

    await ProfileEndpoint.updatePathwayProgress(
      activeCourse.id,
      "in progress",
      // updatedLearningProgress,
      updatedLearningProgress.progress,
      "course",
      roadmapId
    );

    setRegenerating(false);
  };

  const ThumbnailBackground = () => {
    const backgroundStyle = thumbnail ? `url(${thumbnail})` : "#292929";
    const filterStyle =
      thumbnail && thumbnail?.length > 0 ? "blur(15px)" : "none";

    return (
      <Box
        className="thumbnail"
        style={{
          background: backgroundStyle,
          filter: filterStyle,
        }}
      />
    );
  };

  const DefaultThumbnail = () => (
    <Box>
      <img
        alt="default thumbnail"
        style={{ height: "unset" }}
        src={gallery}
        width="40"
        height="40"
      />
    </Box>
  );

  const ImageThumbnail = () => <img alt="" src={thumbnail} height="139" />;

  const getId = (url: string) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <LearningCardWrap>
      <Box className="thumbnail-wrap">
        <ThumbnailBackground />
        {resourceType !== "video" && !thumbnail && <DefaultThumbnail />}

        {resourceType === "video" && getVideoType(link) === "YouTube" ? (
          // <VideoPlayer />
          <iframe
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={`//www.youtube.com/embed/${getId(link)}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <ImageThumbnail />
        )}
      </Box>
      <Box
        className="details"
        sx={
          status
            ? {
                "& button": {
                  border: "1px solid #696969 !important",
                  color: "#FEFEFE !important",
                },
              }
            : {}
        }
      >
        {title?.length > 0 && (
          <a href={link} target="_blank" rel="noreferrer">
            <Typography component="h4">{title}</Typography>
          </a>
        )}
        {description?.length > 0 && <Typography>{description}</Typography>}

        <FooterRow>
          <CustomButton
            size="medium"
            preImg={
              status && (
                <CheckCircleOutlineRounded
                  style={{ width: 20, height: 20 }}
                  htmlColor="#1AFF8C"
                />
              )
            }
            // loading={isUpdating}
            type={status ? "secondary" : "grey"}
            children={status ? "Completed" : "Mark as complete"}
            onClick={handleToggleContentStatus}
          />
          {bonusContent?.length > 0 && (
            <>
              {regenerating && (
                <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
              )}
              {!regenerating && (
                <DetailedCustomTooltip
                  tooltipId="learningInfo-card"
                  title={
                    <Box>
                      <Typography component="h4">Refresh Button</Typography>
                      <Typography>
                        This button enables you to swap the current learning
                        material for a different one. Click to refresh and
                        discover new content that can further enhance your
                        understanding and learning experience.
                      </Typography>
                    </Box>
                  }
                >
                  <StyledRefresh onClick={handleRegenerate}>
                    {RefreshSvg}
                  </StyledRefresh>
                </DetailedCustomTooltip>
              )}
            </>
          )}
          <DislikeWrap onClick={handleDislike}>
            <DislikeBox reacted={reacted ? "true" : "false"}>
              {dislikeSvg}
            </DislikeBox>
          </DislikeWrap>
        </FooterRow>
      </Box>
    </LearningCardWrap>
  );
};

export default CourseLearningCard;

type LearningCardProps = {
  id: string;
  thumbnail?: string;
  title: string;
  link: string;
  description: string;
  resourceType?: "link" | "video";
  status: boolean;
  bonusContent: ContentItem[];
  cardIndex: number;
  roadmapId: string;
};

const LearningCardWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "#1E1E1E",
  borderRadius: 20,
  width: "100%",
  overflow: "hidden",

  "& .thumbnail-wrap": {
    position: "relative",
    width: "100%",
    height: 169,
    overflow: "hidden",
    backgroundColor: "#292929",
    "& .thumbnail": {
      height: "100%",
      backgroundSize: "contain",
      overflow: "hidden",
    },
    "& img": {
      position: "absolute",
      height: "100%",
      maxWidth: "100%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& iframe": {
      position: "absolute",
      height: "100%",
      maxWidth: "100%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 2,
    },
  },
  "& .details": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    padding: "16px 20px 20px",
    "& a": {
      width: "100%",
    },
    "& h4": {
      lineHeight: "1.5rem",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#FEFEFE",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      minHeight: 48,
    },
    "& p": {
      lineHeight: "1.25rem",
      fontWeight: 500,
      fontSize: "0.875rem",
      color: "#FEFEFE",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      marginBottom: 6,
      minHeight: 60,
      width: "100%",
    },
    "& button": {
      padding: "8px 12px !important",
    },
  },
  [theme.breakpoints.down(1110)]: {
    width: "100%",
  },
  [theme.breakpoints.down(860)]: {
    width: "100%",
  },
}));

const DislikeWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  cursor: "pointer",
  "& svg": {
    color: "#FEFEFE",
  },
  "& div": {
    cursor: "pointer",
  },
});

const DislikeBox = styled(Box)<{ reacted: string }>(({ theme, reacted }) => ({
  display: "flex",
  alignItems: "center",
  padding: "3px",
  borderRadius: reacted === "true" ? "4px" : 0,
  background: reacted === "true" ? "#292929" : "",
  cursor: "pointer",
}));

const FooterRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  [theme.breakpoints.down(1200)]: {
    "& button": {
      fontSize: "0.75rem !important",
    },
  },
}));

const StyledRefresh = styled(Icon)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FEFEFE",
  cursor: "pointer",
});
